/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import SuccessModal from "../../components/Modal/successModal";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { openNotificationWithIcon } from "../../components/Modal/notification";
import { Form, Select, Button as AntdBtn } from "antd";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";
import { Container, Button, FormGroup, Input } from "reactstrap";
import { getCustomer, updateCoustomer } from "../../data/customer.js";
import PencilIcon from "../../assets/images/pencil.png";
import * as ST from "./styles";
import { getCompanyInfo } from "../../data/comany";
import usStates from "../../constants/usStates.js";

// const discountOptions = ['N/A', '']
const phoneCheck = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const { Option } = Select;
const termOptions = ["Due on receipt", "Net 15", "Net 30"];
const Projects = () => {
  const [modal_center, setmodal_center] = useState(false);
  const [companyInfo, setCompanyInfo] = useState();
  const [editData, setEditData] = useState();
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { currentUser, currentCompany } = useSelector((state) => ({
    currentUser: state.Login.user,
    currentCompany: state.Login.company,
  }));
  useEffect(() => {
    getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]?.discount?.split(","));
      }
    });
  }, []);
  useEffect(() => {
    getCustomer({ id: id, company_id: currentUser?.company_id }).then((res) => {
      var params = {
        customer_name: res[0].customer_name,
        customer_email: res[0].customer_email,
        customer_phone: res[0].customer_phone,
        city: res[0].city,
        distance: res[0].distance,
        state: res[0].state,
        street: res[0].street,
        terms: res[0].terms,
        zip: res[0].zip,
        contacts: JSON.parse(res[0].contacts) || [],
      };
      form.setFieldsValue(params);
      setEditData(params);
    });
  }, [id]);
  const validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const tog_center = () => {
    setmodal_center(!modal_center);
    // removeBodyCss()
  };
  const goBack = () => {
    history.push("/customers");
  };
  const handleSubmit = (val) => {
    if (
      currentCompany?.trialEndDate < Math.floor(Date.now() / 1000) &&
      !currentCompany?.session_id
    ) {
      openNotificationWithIcon(
        "error",
        "Note",
        "You must have an active subscription or trial to use this feature."
      );
      return;
    }
    if (val.contacts) {
      val.contacts = JSON.stringify(val.contacts)
    }
    if (currentUser.role_id !== 9 && currentUser.role_id !== 10) {
      const data = val;
      data.id = id;
      data.state = data?.state?.toUpperCase();
      updateCoustomer(val)
        .then((res) => {
          // openNotificationWithIcon('success', 'Note', 'Updated successfully')
          tog_center();
          setTimeout(function () {
            history.push("/customers");
          }, 500);
        })
        .catch((err) => {
          openNotificationWithIcon("error", "Note", "Failed");
        });
    } else {
      openNotificationWithIcon(
        "error",
        "Note",
        "You must be a part of a company before using this feature."
      );
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit Customer | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.EditWrapper>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="DOP" breadcrumbItem="Customer/Edit" />
              <header className="back d-print-none" onClick={() => goBack()}>
                <i className="dripicons-arrow-thin-left"></i>
                <span>Back</span>
              </header>
              <div className="row header">
                <div className="col-md-12">Edit Customer</div>
              </div>
              <Form onFinish={handleSubmit} form={form}>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <Form.Item
                      name="customer_name"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <FormGroup>
                        <Input placeholder={editData?.customer_name} />
                        <span className="inputTitle">Company Name</span>
                        <img src={PencilIcon} alt="" />
                      </FormGroup>
                    </Form.Item>
                    <div className="row" style={{ marginTop: 0 }}>
                      <div className="col-md-6">
                        <Form.Item
                          name="customer_email"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                            {
                              validator(_, value) {
                                if (value && !value.match(validRegex)) {
                                  return Promise.reject(
                                    "Invalid email address!"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <FormGroup>
                            <Input placeholder={editData?.customer_email} />
                            <span className="inputTitle">Email</span>
                            <img src={PencilIcon} alt="" />
                          </FormGroup>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Form.Item
                          name="customer_phone"
                          rules={[
                            {
                              validator(_, value) {
                                if (value && !phoneCheck.test(value)) {
                                  return Promise.reject(
                                    "Invalid phone number!"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <FormGroup>
                            <Input placeholder={editData?.customer_phone} />
                            <span className="inputTitle">Phone Number</span>
                            <img src={PencilIcon} alt="" />
                          </FormGroup>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 0 }}>
                      <div className="col-md-12">
                        <Form.Item name="terms">
                          <FormGroup>
                            <Input
                              type="select"
                              defaultValue={editData?.customer_id}
                            >
                              <option></option>
                              {termOptions &&
                                termOptions.map((res) => (
                                  <>
                                    {res === editData?.terms ? (
                                      <option key={res} value={res} selected>
                                        {res}
                                      </option>
                                    ) : (
                                      <option key={res} value={res}>
                                        {res}
                                      </option>
                                    )}
                                  </>
                                ))}
                            </Input>
                            <span className="inputTitle">Terms</span>
                            <DownOutlined />
                          </FormGroup>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item name="discount">
                      <FormGroup>
                        <Input
                          type="select"
                          defaultValue={editData?.customer_id}
                        >
                          <option value="N/A">N/A</option>
                          {companyInfo &&
                            companyInfo.map((res) => (
                              <>
                                {res === editData?.discount ? (
                                  <option key={res} value={res} selected>
                                    {res}%
                                  </option>
                                ) : (
                                  <option key={res} value={res}>
                                    {res}%
                                  </option>
                                )}
                              </>
                            ))}
                        </Input>
                        <span className="inputTitle">
                          Discount Allegibillity
                        </span>
                        <DownOutlined />
                      </FormGroup>
                    </Form.Item>
                  </div>
                  <div className="col-md-5">
                    <Form.Item name="street">
                      <FormGroup>
                        <Input placeholder={editData?.street} />
                        <span className="inputTitle">Street</span>
                        <img src={PencilIcon} alt="" />
                      </FormGroup>
                    </Form.Item>
                    <Form.Item name="city">
                      <FormGroup>
                        <Input placeholder={editData?.city} />
                        <span className="inputTitle">City</span>
                        <img src={PencilIcon} alt="" />
                      </FormGroup>
                    </Form.Item>
                    <Form.Item
                      name="state"
                      // rules={[
                      //   { pattern: /^[A-Za-z]{2}$/, message: 'Please enter a valid two-letter state abbreviation' },
                      // ]}
                    >
                      <Select
                        showSearch
                        className="antdSelect"
                        placeholder="Search to States"
                        options={usStates}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="zip"
                      rules={[
                        {
                          pattern: /^\d{5}(-\d{4})?$/,
                          message:
                            "Please enter a valid ZIP code (XXXXX or XXXXX-XXXX)",
                        },
                      ]}
                      style={{ marginTop: 40 }}
                    >
                      <FormGroup>
                        <Input placeholder={editData?.zip} />
                        <span className="inputTitle">Zip</span>
                        <img src={PencilIcon} alt="" />
                      </FormGroup>
                    </Form.Item>
                  </div>
                  <div className="col-md-1"></div>
                </div>

                <Form.List name="contacts">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          className="row"
                          style={{
                            marginTop: -30,
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <div className="col-md-1"></div>
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-4">
                                <Form.Item
                                  {...restField}
                                  name={[name, "contact_firstname"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <FormGroup>
                                    <Input placeholder={editData?.contacts[key]?.contact_firstname} />
                                    <span className="inputTitle">
                                      Contact First Name
                                    </span>
                                    <img src={PencilIcon} alt="" />
                                  </FormGroup>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <Form.Item
                                  {...restField}
                                  name={[name, "contact_lastname"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <FormGroup>
                                    <Input placeholder={editData?.contacts[key]?.contact_lastname} />
                                    <span className="inputTitle">
                                      Contact Last Name
                                    </span>
                                    <img src={PencilIcon} alt="" />
                                  </FormGroup>
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <Form.Item
                                  {...restField}
                                  name={[name, "contact_email"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                    {
                                      validator(_, value) {
                                        if (value && !value.match(validRegex)) {
                                          return Promise.reject(
                                            "Invalid email address!"
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <FormGroup>
                                    <Input placeholder={editData?.contacts[key]?.contact_email} />
                                    <span className="inputTitle">
                                      Contact Email
                                    </span>
                                    <img src={PencilIcon} alt="" />
                                  </FormGroup>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1">
                            <CloseOutlined onClick={() => remove(name)} />
                          </div>
                        </div>
                      ))}
                      <div className="row" style={{ marginTop: -10 }}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <AntdBtn type="dashed" onClick={() => add()} block>
                            + Add Other Contact
                          </AntdBtn>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </div>
                  )}
                </Form.List>
                <div className="row">
                  <div className="col-md-5">
                    <Button htmlType="submit">Edit & Save</Button>
                  </div>
                </div>
              </Form>
            </Container>
            <SuccessModal
              title="Successfully!"
              content="Customer Updated"
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
            />
          </ST.EditWrapper>
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;

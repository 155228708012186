import React from 'react'
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'

const TrComponent = ({ tableData, companyInfo }) => {
  const history = useHistory();
  const goView = val => {
    history.push(`/invoices/view/${val}`)
  }
  return (
    <tr>
      <td>
        <Link to={`/invoices/view/${tableData.id}`}>
          {/* {companyInfo?.invoice_start_prefix ? (
            <>{companyInfo?.invoice_start_prefix}-{tableData?.invoice_id}</>
          ) : (
            <>{tableData?.invoice_id}</>
          )} */}
          <>{tableData?.ses_project_id}-{tableData?.jobNumber}</>
        </Link>
      </td>
      <td>
        <Link to={`/reports/view/${tableData.reportId}`}>
          {/* {companyInfo?.invoice_start_prefix ? (
            <>{companyInfo?.invoice_start_prefix}-{tableData?.invoice_id}</>
          ) : (
            <>{tableData?.invoice_id}</>
          )} */}
          <>{tableData?.ses_project_id}-{tableData?.jobNumber}</>
        </Link>
      </td>
      <td>{moment(tableData?.invoice_date).format('MM/DD/YYYY HH:mm:ss')}</td>
      <td>
        <Link to={`/projects/machines/${tableData.project_id}`}>
          {tableData?.ses_project_id && (
            tableData?.ses_project_id
          )}
        </Link>
      </td>
      <td>
        <Link to={`/customers/detail/${tableData.customer_id}`}>
          {tableData?.customer_name}
        </Link>
      </td>
      <td>{tableData?.reference_id}</td>
      {tableData?.status ? (
        <td className='greenBg'>Paid</td>
      ): <td>Billed</td>}
      <td>
        {!tableData?.status ? (
          <a href='#' onClick={() => goView(tableData?.id)}>Send</a>
        ) : (
          <a href='#' style={{ color: 'green !important' }} onClick={() => goView(tableData?.id)}>View</a>
        )}
      </td>
    </tr>
  )
}

export default TrComponent

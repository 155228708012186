/* eslint-disable */
import styled from 'styled-components'

export const Wrapper = styled.div`
  .containerFix {
    padding: 0 80px;
    background: whitesmoke;
  }
  header {
    .containerFix .row {
      padding: 15px 0;
    }
    .linkSection {
      display: flex;
      justify-content: end;
      align-items: center;
      a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7F7F7F;
        margin-left: 40px;
      }
      a: last-child {
        background: linear-gradient(90deg, #D14124 -0.05%, #B02509 99.95%);
        box-shadow: 0px 4px 25px rgba(255, 0, 0, 0.2);
        border-radius: 4px;
        padding: 10px 40px;
        color: white;
      }
    }
  }
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logoSection {
      margin-bottom: 20px;
      margin-top: 20px;
      img {
        width: 25%;
      }
    }
  
    .linkSection {
      flex-direction: column;
    }
  
    .linkSection a {
      margin: 0;
      margin-bottom: 10px;
    }
    .containerFix {
      padding: 0 30px;
    }
  }
`
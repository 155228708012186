/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import { openNotificationWithIcon } from "../../components/Modal/notification";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getProject } from "../../data/project.js";
import { Checkbox, Button, Select, Row, Col, Form } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { Container, Table, Input, FormGroup } from "reactstrap";
import * as ST from "./styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import { SendInvoice, getInvoiceNum, getJobNumber } from "../../data/invoice.js";
import SuccessModal from "../../components/Modal/successModal";
import { getUsersByCompanyId } from "../../data/user";

const validRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const { Option } = Select;
const Projects = () => {
  const pdfExportComponent = useRef(null);
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [editData, setEditData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [companyDiscount, setCompanyDiscount] = useState();
  const [discountStatus, setDiscountStatus] = useState(true);
  const [discountValue, setDiscountValue] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState();
  const [otherContactStatus, setOtherContactStatus] = useState(false);
  const [isOthers, setIsOthers] = useState(false);

  const { id } = useParams();
  const [form] = Form.useForm();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Login.user,
  }));
  const nowDate = new Date();
  useEffect(() => {
    getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
        setCompanyDiscount(res[0]?.discount?.split(","));
        form.setFieldsValue({ ...res[0], technician_email: currentUser.email });
      }
    });
  }, []);
  useEffect(() => {
    getInvoiceNum({ id: currentUser?.company_id }).then((res) => {
      if (res[0].num >= companyInfo?.invoice_start) {
        setInvoiceId(Number(Number(res[0].num) + 1));
      } else {
        setInvoiceId(companyInfo?.invoice_start);
      }
    });
  }, [companyInfo]);

  const { result } = getMachines({ project_id: id });
  useEffect(() => {
    getProject({ id: id }).then((res) => {
      var params = {
        customer_email: res[0].customer_email,
        customer_name: res[0].customer_name,
        customer_phone: res[0].customer_phone,
        // contact_name: res[0].contact_name,
        contacts: JSON.parse(res[0].contacts) || [],
        project_name: res[0].project_name,
        customer_id: res[0].customer_id,
        ses_project_id: res[0].ses_project_id,
        reference_id: res[0].reference_id,
        project_city: res[0].project_city,
        project_state: res[0].project_state,
        project_street: res[0].project_street,
        project_zip: res[0].project_zip,
        project_id: id,
        invoice_date: moment(nowDate).format("YYYY-MM-DD HH:mm:ss"),
        city: res[0].city,
        state: res[0].state,
        street: res[0].street,
        zip: res[0].zip,
        discount: res[0].discount,
        terms: res[0].terms,
        extra_test_num: result?.length < 4 ? 0 : result?.length - 4,
        sender_user_id: currentUser?.sub,
        invoice_id: invoiceId,
        Status: res[0].status,
        job_number: 1,
      };

      form.setFieldsValue({
        ...res[0],
        contact_email_report: res[0].contact_email,
      });
      getJobNumber({ ses_project_id: params.ses_project_id }).then((res) => {
        params = { ...params, job_number: res.job_number }
        setEditData(params);
        if (params.discount !== "N/A") {
          // setDiscountStatus(false)
          setDiscountValue(params.discount);
        }
      });
    });
  }, [id, result, invoiceId]);

  useEffect(() => {
    if (currentUser?.company_id) {
      getUsersByCompanyId({ company_id: currentUser?.company_id }).then(
        (res) => {
          setCompanyUsers(
            res?.filter((e) => e?.firstname !== "" && e?.lastname !== "")
          );
        }
      );
    } else {
      setCompanyUsers([]);
    }
  }, []);

  const tog_center = () => {
    setmodal_center(!modal_center);
  };
  const goMachine = () => {
    history.push(`/projects/machines/${id}`);
  };
  const handleExportWithComponent = (event) => {
    pdfExportComponent.current.save();
  };
  const onChangeCheckbox = (val) => {
    if (val.target.checked) {
      setDiscountStatus(false);
    } else {
      setDiscountStatus(true);
    }
  };
  const onChangeDiscount = (val) => {
    setDiscountValue(val.target.value);
  };
  const sendInvoice = (val) => {
    const params = {
      editData: editData,
      companyInfo: companyInfo,
      discount: !discountStatus && discountValue ? discountValue : 0,
      subtotal:
        companyInfo?.initial_rate +
        editData?.extra_test_num * companyInfo?.additional_rate,
      resend: false,
      tableData: result,
      Status: editData?.Status,
      currentUser: { ...currentUser, email: val.technician_email },
      ...val,
    };
    if (editData.Status) {
      let con = confirm("Are you sure to send new invoice?");
      if (con) {
        setInvoiceButtonLoading(true);
        SendInvoice(params).then((res) => {
          if (res) {
            setmodal_center(true);
            setInvoiceButtonLoading(false);
            setIsModalVisible(false);
          }
        });
      }
    } else {
      setInvoiceButtonLoading(true);
      SendInvoice(params).then((res) => {
        if (res) {
          setmodal_center(true);
          setInvoiceButtonLoading(false);
          openNotificationWithIcon(
            "success",
            "Note",
            "Successfully sent invoice."
          );
          history.push(`/reports/${id}`);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project/machines | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.GeneratedWrapper>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="DOP" breadcrumbItem="Project/Machines" />
              <div className="back d-print-none" onClick={() => goMachine()}>
                <i className="dripicons-arrow-thin-left"></i>
                <span>Back</span>
              </div>
              {/* <div style={{ textAlign: "center" }}>
                <Select
                  defaultValue={layoutSelection}
                  onChange={(e) => setLayoutSelection(e)}
                  style={{ width: 200 }}
                >
                  <Option value="size-a4">A4</Option>
                  <Option value="size-letter">Letter</Option>
                  <Option value="size-executive">Execution</Option>
                </Select>
              </div> */}
              <div className="table-responsive">
                <PDFExport ref={pdfExportComponent}>
                  <div
                    className={`pdf-page ${layoutSelection}`}
                    style={{ padding: ".4in  .3in" }}
                  >
                    {/* <div className='row panelFirst'>
                      <div className='col-md-6'>
                        <div style={{ minHeight: 70 }}>
                          <img src={`https://s3.amazonaws.com/${process.env.REACT_APP_BUCKET_NAME}/${companyInfo?.logo}`} height='70' alt='' />
                        </div>
                        <div>{companyInfo?.street}</div>
                        <div>{companyInfo?.city}, {companyInfo?.state} {companyInfo?.zip}</div>
                      </div>
                      <div className='col-md-6' style={{ textAlign: 'right' }}>
                        <div>INVOICE</div>
                        <aside className='invoiceDateTable'>
                          <div>
                            <section>INVOICE DATE</section>
                            <section>INVOICE NO.</section>
                          </div>
                          <div>
                            <section>{editData?.invoice_date}</section>
                            <section>{editData?.invoice_id}</section>
                          </div>
                        </aside>
                      </div>
                    </div> */}
                    <Row
                      className="panelFirst"
                      style={{ paddingBottom: 1, borderBottom: "3px solid" }}
                    >
                      <Col span={12}>
                        <div style={{ minHeight: 55 }}>
                          <img
                            src={`${process.env.REACT_APP_URL}/company-logo/${companyInfo?.logo}`}
                            height="55"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          fontSize: 7,
                          display: "flex",
                          alignItems: "flex-end",
                          whiteSpace: "nowrap",
                          justifyContent: "right",
                          fontFamily: "serif",
                        }}
                      >
                        {companyInfo?.company_slogan}
                      </Col>
                    </Row>
                    <div
                      style={{
                        textAlign: "center",
                        margin: 10,
                        fontWeight: "600",
                        fontSize: 10,
                        fontFamily: 'ArialBoldFont',
                      }}
                    >
                      DOP AEROSOL TEST CERTIFICATION INVOICE
                    </div>
                    <Row className="title" gutter={[8, 8]}>
                      <Col span={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} style={{ textWrap: "nowrap" }}>
                            Invoice Date:{" "}
                            <span style={{ borderBottom: "1px solid #000000" }}>
                              {moment(nowDate).format("MM/DD/YYYY")}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} style={{ textWrap: "nowrap" }}>
                            SES Invoice No.:{" "}
                            {/* <span style={{ borderBottom: "1px solid #000000" }}>
                              {companyInfo?.invoice_start_prefix
                                ? companyInfo.invoice_start_prefix +
                                  "-" +
                                  editData?.invoice_id
                                : editData?.invoice_id}
                            </span> */}
                            {editData?.ses_project_id}-{editData?.job_number}
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col span={8}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} style={{ textWrap: "nowrap" }}>
                            SES Job No.:{" "}
                            <span style={{ borderBottom: "1px solid #000000" }}>
                              {companyInfo?.invoice_start_prefix
                                ? companyInfo.invoice_start_prefix +
                                  "-" +
                                  editData?.ses_project_id
                                : editData?.ses_project_id}-1
                              {editData?.ses_project_id}-{editData?.job_number}
                            </span>
                          </Col>
                        </Row>
                      </Col> */}
                    </Row>
                    {/* <div className='row panel'>
                      <div className='col-md-4'>
                        <div><span>Bill to:</span></div>
                        <div><span>{editData?.customer_name}</span></div>
                        <div><span>{editData?.street}</span></div>
                        <div>{editData?.city}, {editData?.state}</div>
                        <div><span>{editData?.zip}</span></div>
                      </div>
                      <div className='col-md-4'>
                        <div>Project Name:</div>
                        <div><span>{editData?.project_name}</span></div>
                        <div>Project Address:</div>
                        <div><span>{editData?.project_street}</span></div>
                        <div><span>{editData?.project_city}, {editData?.project_state}</span></div>
                        <div><span>{editData?.project_zip}</span></div>
                      </div>
                      <div className='col-md-4' style={{ textAlign: 'right' }}>
                        <div><span>SES Project: {editData?.ses_project_id}</span></div>
                        <div><span>Customer Reference: {editData?.reference_id}</span></div>
                        <div>P.O.NO. 88765</div>
                        <div><br /></div>
                        <div>TERMS: {editData?.terms}</div>
                      </div>
                    </div> */}
                    <Row className="panel" gutter={[8, 8]}>
                      <Col span={12}>
                        <section>
                          <div>Client:</div>
                          <div>{editData?.customer_name}</div>
                          <div>{editData?.street}</div>
                          <div>
                            {editData?.city}, {editData?.state}, {editData?.zip}
                          </div>
                          <div>Phone: {editData?.customer_phone}</div>
                          <div>
                            Email:{" "}
                            <a href="#" style={{ borderBottom: "1px solid" }}>
                              {editData?.customer_email}
                            </a>
                          </div>
                          <div>
                            Attention:{" "}
                            {editData?.contacts?.[0]?.contact_firstname}{" "}
                            {editData?.contacts?.[0]?.contact_lastname}
                          </div>
                        </section>
                      </Col>
                      <Col span={12}>
                        <section>
                          <div>Project Information:</div>
                          <div>{editData?.project_name}</div>
                          <div>{editData?.project_street}</div>
                          <div>
                            {editData?.project_city}, {editData?.project_state}{" "}
                            {editData?.project_zip}
                          </div>
                          <div>Client Ref No.: {editData?.reference_id}</div>
                        </section>
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <Table className="table table-bordered">
                        <thead>
                          <tr>
                            <th width="60%">DESCRIPTION</th>
                            <th>QTY</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>EQUIPMENT TESTING-4 units</td>
                            <td>1</td>
                            <td>{companyInfo?.initial_rate}</td>
                            <td>{companyInfo?.initial_rate.toFixed(2)}</td>
                          </tr>
                          {editData?.extra_test_num !== 0 && (
                            <tr>
                              <td>Extra units tested</td>
                              <td>{editData?.extra_test_num}</td>
                              <td>{companyInfo?.additional_rate}</td>
                              <td>
                                {(
                                  editData?.extra_test_num *
                                  companyInfo?.additional_rate
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className="colorNone">'</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="colorNone">'</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="colorNone">'</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="grayTr">
                            <td></td>
                            <td colspan="2">SUBTOTAL</td>
                            <td>
                              {(
                                companyInfo?.initial_rate +
                                editData?.extra_test_num *
                                  companyInfo?.additional_rate
                              ).toFixed(2)}
                            </td>
                          </tr>
                          {!discountStatus && (
                            <tr className="grayTr">
                              <td></td>
                              <td colspan="2">
                                DISCOUNT{" "}
                                {discountValue ? `${discountValue}%` : ""}
                              </td>
                              <td>
                                {discountValue
                                  ? (
                                      (companyInfo?.initial_rate +
                                        editData?.extra_test_num *
                                          companyInfo?.additional_rate) *
                                      (discountValue / 100)
                                    ).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td></td>
                            <td colspan="2">INVOICE TOTAL</td>
                            <td>
                              {!discountStatus && discountValue
                                ? (
                                    companyInfo?.initial_rate +
                                    editData?.extra_test_num *
                                      companyInfo?.additional_rate -
                                    (companyInfo?.initial_rate +
                                      editData?.extra_test_num *
                                        companyInfo?.additional_rate) *
                                      (discountValue / 100)
                                  ).toFixed(2)
                                : (
                                    companyInfo?.initial_rate +
                                    editData?.extra_test_num *
                                      companyInfo?.additional_rate
                                  ).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <span>
                          {companyInfo?.street}, {companyInfo?.city},{" "}
                          {companyInfo?.state} {companyInfo?.zip}
                        </span>
                        <span style={{ marginLeft: 40 }}>
                          {companyInfo?.phone}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        width: "calc(100% - 0.6in)",
                        bottom: "0.4in",
                      }}
                    >
                      <Row>
                        <Col span={12}>
                          <div
                            style={{
                              fontSize: 8,
                              border: "1px solid",
                              textAlign: "center",
                              padding: 5,
                              fontFamily: "Arial",
                            }}
                          >
                            <div style={{ marginBottom: 10 }}>
                              Remit Payment to:
                            </div>
                            <div>{companyInfo?.company_name}</div>
                            <div>{companyInfo?.street}</div>
                            <div>
                              {companyInfo?.city}, {companyInfo?.state},{" "}
                              {companyInfo?.zip}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </PDFExport>
              </div>
              <footer className="d-print-none">
                {companyDiscount && (
                  <div style={{ textAlign: "center" }}>
                    <Checkbox
                      onChange={onChangeCheckbox}
                      checked={!discountStatus}
                    >
                      Enable Discount
                    </Checkbox>
                    {/* <Checkbox onChange={onChangeCheckbox} checked={!discountStatus} disabled>Enable Discount</Checkbox> */}
                    <FormGroup>
                      <Input
                        type="select"
                        value={discountValue}
                        disabled={discountStatus}
                        onChange={onChangeDiscount}
                      >
                        {/* <Input type="select" value={discountValue} disabled onChange={onChangeDiscount} > */}
                        <option value="">N/A</option>
                        {companyDiscount &&
                          companyDiscount.map((res) => (
                            <>
                              {editData?.discount && (
                                <>
                                  {Number(res) <= Number(editData.discount) ? (
                                    <option key={res} value={res}>
                                      {res}%
                                    </option>
                                  ) : (
                                    <option
                                      style={{ background: "gainsboro" }}
                                      key={res}
                                      value={res}
                                      disabled="disabled"
                                    >
                                      {res}%
                                    </option>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                      </Input>
                      <DownOutlined />
                    </FormGroup>
                  </div>
                )}
                <div style={{ textAlign: "center" }}>
                  {/* <Button style={{ marginRight: 20 }}  className='newCustomer' onClick={() => handleExportWithComponent()}>Download PDF</Button> */}
                  <Button
                    disabled={invoiceButtonLoading}
                    className="newCustomer"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Save & Send
                  </Button>
                </div>
              </footer>
            </Container>
            <ST.StyleModal
              title="Edit"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={sendInvoice}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  // initialValues={{ email: editUserData?.email }}
                >
                  <h3>Invoice</h3>
                  <hr />
                  <Form.Item
                    name="technician_email"
                    label="Technician:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="copy_invoices_user"
                    label="Send copies of invoices to:"
                  >
                    <Select
                      showSearch
                      className="antdSelect"
                      defaultValue="N/A"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option.title
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {companyUsers &&
                        companyUsers.map((res) => (
                          <Option
                            key={[res.email]}
                            title={`${res.firstname} ${res.lastname}`}
                            value={res.id}
                            disabled={res.leave}
                          >
                            {res.firstname} {res.lastname}
                          </Option>
                        ))}
                      <Option value={null}>N/A</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="customer_email"
                    label="Customer:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() =>
                        setOtherContactStatus(!otherContactStatus)
                      }
                      checked={otherContactStatus}
                    >
                      Other customer contacts:
                    </Checkbox>
                  </Form.Item>
                  {otherContactStatus && (
                    <Form.Item name="contact_emails" label="Contacts:">
                      <Select
                        mode="multiple"
                        size="middle"
                        placeholder="Please select"
                        style={{
                          width: "100%",
                        }}
                      >
                        {editData?.contacts &&
                          editData.contacts.map((res, index) => (
                            <Option key={index} value={res.contact_email}>
                              {res.contact_email +
                                " (" +
                                res.contact_firstname +
                                "  " +
                                res.contact_lastname +
                                ")"}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() => setIsOthers(!isOthers)}
                      checked={isOthers}
                    >
                      To other:
                    </Checkbox>
                  </Form.Item>
                  {isOthers && (
                    <Form.Item
                      name="other_email"
                      label="Other:"
                      rules={[
                        {
                          validator(_, value) {
                            if (value && !value.match(validRegex)) {
                              return Promise.reject("Invalid email address!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          height: "38px",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Button
                      htmlType="button"
                      onClick={() => setIsModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={invoiceButtonLoading}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </p>
            </ST.StyleModal>
            <SuccessModal
              title="Successfully!"
              content="Your sent invoice to customer"
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
            />
          </ST.GeneratedWrapper>
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
// import { PDFExport } from  '@progress/kendo-react-pdf'

//import Breadcrumbs
// import TrComponent from '../../components/Projects/TrMachines'
import moment from "moment";
import { Select } from "antd";
import {
  // Table,
  Button,
} from "reactstrap";
import * as ST from "../Reports/styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import { getReportByUser, getReportById } from "../../data/report.js";
import Pdf from "../Reports/pdf.js";

const { Option } = Select;

const PrintReport = () => {
  // const pdfExportComponent = useRef(null)
  const [editData, setEditData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const [reportData, setReportData] = useState();
  const [pdfPageNumber, setPdfPageNumber] = useState();

  const { id } = useParams();
  const { result, mutate } = getMachines({ project_id: editData?.project_id });

  useEffect(() => {
    getReportByUser({ id: editData?.project_id }).then((res) => {
      setReportData({ ...res[0], job_number: res[0]?.job_number - 1 });
    });
  }, [editData]);

  useEffect(() => {
    getReportById({ id: id }).then((res) => {
      console.log(res);
      if (res?.length) {
        var params = {
          customer_email: res[0].customer_email,
          customer_name: res[0].customer_name,
          customer_phone: res[0].customer_phone,
          contacts: JSON.parse(res[0].contacts) || [],
          contact_name: res[0].contact_name,
          project_name: res[0].project_name,
          customer_id: res[0].customer_id,
          ses_project_id: res[0].ses_project_id,
          reference_id: res[0].reference_id,
          project_city: res[0].project_city,
          project_state: res[0].project_state,
          project_street: res[0].project_street,
          project_zip: res[0].project_zip,
          project_id: res[0].project_id,
          ID: res[0].ID,
          company_id: res[0].company_id,
          copy_reports_user: res[0].copy_reports_user,
          report_date: moment(res[0].report_date).format("MM/DD/YYYY"),
          city: res[0].city,
          state: res[0].state,
          street: res[0].street,
          zip: res[0].zip,
          tested_at: moment(res[0].tested_at).format("MM/DD/YYYY"),
          job_number: res[0].job_number,
        };
        setEditData(params);
      }
    });
  }, [id]);

  useEffect(() => {
    getCompanyInfo({ id: editData?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
      }
    });
  }, [editData]);
  useEffect(() => {
    if (result?.length) {
      setPdfPageNumber(Math.ceil(result?.length / 14));
    }
  }, [result]);

  const handleExportWithComponent = async (event) => {
    try {
      // const pdfContent =
      //   document.getElementsByClassName("pdf-page")[0].innerHTML;
      var pdfContent = "";
      for (var i = 0; i < pdfPageNumber; i++) {
        document.getElementsByClassName("footer-content")[i].style.display =
          "none";
        pdfContent += document.getElementsByClassName("pdf-page")[i].innerHTML;
        if (i !== pdfPageNumber - 1) {
          pdfContent += "<div class='page-break'></div>";
        }
        document.getElementsByClassName("footer-content")[i].style.display =
          "block";
      }
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/report/makePdf",
        {
          method: "POST",
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: pdfContent, data: { reportData: reportData, companyInfo: companyInfo, url: `${process.env.REACT_APP_URL}/company-logo/` } }),
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "example.pdf"; // Set the filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // pdfExportComponent.current.save();
  };
  return (
    <>
      <MetaTags>
        <title>Print | DOP Test Network</title>
      </MetaTags>
      {editData && (
        <ST.GeneratedWrapper>
          <Select
            defaultValue={layoutSelection}
            onChange={(e) => setLayoutSelection(e)}
            style={{
              marginLeft: "calc( 50% - 200px + 100px )",
              width: 200,
              marginTop: 30,
            }}
          >
            <Option value="size-a4">A4</Option>
            <Option value="size-letter">Letter</Option>
            <Option value="size-executive">Execution</Option>
          </Select>

          <div className={`pdf-page ${layoutSelection}`}>
            {/* <Pdf
              companyInfo={companyInfo}
              editData={editData}
              result={result}
              reportData={reportData}
            /> */}
            {Array.from({ length: pdfPageNumber }, (_, i) => (
              <div key={i} className={`pdf-page ${layoutSelection}`}>
                <Pdf
                  companyInfo={companyInfo}
                  editData={editData}
                  result={result}
                  reportData={reportData}
                  index={i}
                  pageLength={pdfPageNumber}
                />
              </div>
            ))}
          </div>
          {/* <PDFExport  ref={pdfExportComponent}>
            <div className={ `pdf-page ${ layoutSelection }` }>
              <Row className='panelFirst' style={{ paddingBottom: 5, borderBottom: '3px solid' }}>
                <Col span={12}>
                  <div style={{ minHeight: 70 }}>
                    <img src={`${process.env.REACT_APP_URL}/company-logo/${companyInfo?.logo}`} height='70' alt='' />
                  </div>
                </Col>
                <Col span={12} style={{ fontSize: 8, display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap', justifyContent: 'right', fontFamily: 'Arial' }}>
                  {companyInfo?.company_slogan}
                </Col>
              </Row>
              <div style={{ textAlign: 'center', margin: 10, fontWeight: '600' }}>
                DOP AEROSOL TEST CERTIFICATION REPORT
              </div>
              <Row className='title' gutter={[8, 8]}>
                <Col span={8}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} style={{ textWrap: 'nowrap' }}>
                      Report Date: {moment(reportData?.report_date?.split(' ')[0]).format('MM/DD/YYYY')}
                    </Col>
                  </Row>
                </Col>
                <Col span={7}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} style={{ textWrap: 'nowrap' }}>
                      Test Date: {moment(editData?.tested_at).format('MM/DD/YYYY')}
                    </Col>
                  </Row>
                </Col>
                <Col span={9}>
                  <Row gutter={[8, 8]}>
                    <Col span={8} style={{ textWrap: 'nowrap' }}>
                      Job No.: {companyInfo?.invoice_start_prefix ? companyInfo.invoice_start_prefix + '-' + editData?.ses_project_id : editData?.ses_project_id}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='panel' gutter={[8, 8]}>
                <Col span={12}>
                  <section>
                    <div>Client:</div>
                    <div>{editData?.customer_name}</div>
                    <div>{editData?.street}</div>
                    <div>{editData?.city}, {editData?.state}</div>
                  </section>
                </Col>
                <Col span={12}>
                  <section>
                    <div>Project Information:</div>
                    <div>{editData?.project_name}</div>
                    <div>{editData?.project_street}</div>
                    <div>{editData?.project_city}, {editData?.project_state}</div>
                    <div>Client Reference No.: {editData?.reference_id}</div>
                  </section>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table table-striped table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Certificate #</th>
                      <th>Equipment Type</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Serial #</th>
                      <th>Pass/Fail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result && result.map((res, index) => (
                      <TrComponent key={index} tableData={res} mutate={mutate}  Role={10}  />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div style={{ position: 'absolute', width: 'calc(100% - 0.6in)', bottom: '0.4in' }}>
                <Row style={{ fontSize: 11 }}>
                  <Col span={12}>
                    <Row>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 20 }}>
                        Inspector Name:
                      </Col>
                      <Col span={14}>
                        <div style={{ borderBottom: '1px solid' }}>
                          {reportData?.firstname} {reportData?.lastname}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={10} style={{ textAlign: 'right', paddingRight: 20 }}>
                        Signature:
                      </Col>
                      <Col span={14}>
                        <div style={{ borderBottom: '1px solid' }}>
                          <img src={reportData?.signature} alt='' style={{ height: '20px' }} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: 7, marginBottom: 3, fontWeight: '600', borderBottom: '3px solid' }}>
                  Testing Performed in Accordance with ANSI/ASME N510-2007
                </div>
                <Row style={{ fontSize: 8, fontFamily: 'Arial' }}>
                  <Col span={12}>
                    <span>{companyInfo?.company_name}: {companyInfo?.street}, {companyInfo?.city}, {companyInfo?.state} {companyInfo?.zip}</span>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <span>Phone: {companyInfo?.phone}</span>
                    <span style={{ marginLeft: 10 }}>Fax: {companyInfo?.phone}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </PDFExport> */}

          <footer
            className="d-print-none"
            style={{ textAlign: "center", marginTop: 30, paddingBottom: 50 }}
          >
            <Button onClick={handleExportWithComponent}>Download PDF</Button>
          </footer>
        </ST.GeneratedWrapper>
      )}
    </>
  );
};

export default PrintReport;

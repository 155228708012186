/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { PDFExport, savePDF } from  '@progress/kendo-react-pdf'
import SuccessModal from "../../components/Modal/successModal";
import { openNotificationWithIcon } from "../../components/Modal/notification";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import TrComponent from '../../components/Projects/TrMachines'
import { getProject } from "../../data/project.js";
import { getUserById } from "../../data/report.js";
import moment from "moment";
import { Select, Form, Input, Checkbox, Button as AntdBtn } from "antd";
import { Container, Button } from "reactstrap";
import * as ST from "./styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import Pdf from "./pdf.js";
import { SendReport, getJobNumber } from "../../data/invoice.js";
import { getUsersByCompanyId } from "../../data/user";

const validRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const { Option } = Select;
const Projects = () => {
  // const pdfExportComponent = useRef(null)
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [editData, setEditData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const [otherContactStatus, setOtherContactStatus] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState();
  const [pdfPageNumber, setPdfPageNumber] = useState();
  const [reportData, setReportData] = useState();

  const { id } = useParams();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Login.user,
  }));
  const [form] = Form.useForm();

  useEffect(() => {
    getUserById({ id: currentUser?.sub }).then((res) => {
      setReportData({ ...res[0], job_number: 1 });
    });
  }, [currentUser]);
  const nowDate = new Date();
  const { result, mutate } = getMachines({ project_id: id });
  useEffect(() => {
    getProject({ id: id }).then((res) => {
      var params = {
        customer_email: res[0].customer_email,
        customer_name: res[0].customer_name,
        customer_phone: res[0].customer_phone,
        contacts: JSON.parse(res[0].contacts) || [],
        project_name: res[0].project_name,
        customer_id: res[0].customer_id,
        ses_project_id: res[0].ses_project_id,
        reference_id: res[0].reference_id,
        project_city: res[0].project_city,
        project_state: res[0].project_state,
        project_street: res[0].project_street,
        project_zip: res[0].project_zip,
        project_id: id,
        report_date: moment(nowDate).format("YYYY-MM-DD HH:mm:ss"),
        city: res[0].city,
        state: res[0].state,
        street: res[0].street,
        zip: res[0].zip,
        tested_at: moment(res[0].tested_at).format("YYYY-MM-DD HH:mm:ss"),
        reporter_user_id: currentUser?.sub,
        technician_name: currentUser?.firstname + " " + currentUser?.lastname,
        sender_user_id: currentUser?.sub,
      };
      form.setFieldsValue({
        ...res[0],
        technician_email: currentUser.email,
      });
      getJobNumber({ ses_project_id: params.ses_project_id }).then((res) => {
        params = { ...params, job_number: res.job_number }
        setEditData(params);
      })
    });
  }, [id]);
  useEffect(() => {
    if (result?.length) {
      setPdfPageNumber(Math.ceil(result?.length / 14));
    }
  }, [result]);

  useEffect(() => {
    if (currentUser?.company_id) {
      getUsersByCompanyId({ company_id: currentUser?.company_id }).then(
        (res) => {
          setCompanyUsers(
            res?.filter((e) => e?.firstname !== "" && e?.lastname !== "")
          );
        }
      );
    } else {
      setCompanyUsers([]);
    }
  }, []);

  useEffect(() => {
    getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
        form.setFieldsValue(res[0]);
      }
    });
  }, []);
  const tog_center = () => {
    setmodal_center(!modal_center);
  };
  const goMachine = () => {
    // history.push(`/projects/machines/${id}`);
    window.history.back();
  };
  // const printInvoice = () => {
  //   window.print();
  //   // history.push(`/projects/printDops/${id}`)
  // };
  const sendReport = (val) => {
    const params = {
      editData: editData,
      companyInfo: companyInfo,
      tableData: result,
      userId: currentUser.sub,
      resend: false,
      currentUser: { ...currentUser, email: val.technician_email },
      ...val,
    };
    setInvoiceButtonLoading(true);
    SendReport(params).then((res) => {
      if (res) {
        setmodal_center(true);
        setInvoiceButtonLoading(false);
        openNotificationWithIcon(
          "success",
          "Note",
          "Successfully sent report."
        );
        history.push(`/projects/machines/${id}`);
      }
    });
  };
  // const handleExportWithComponent = async (event) => {
  //   try {
  //     const pdfContent =
  //       document.getElementsByClassName("pdf-page")[0].innerHTML;
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/report/makePdf",
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/pdf",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ htmlContent: pdfContent }),
  //       }
  //     );
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "example.pdf"; // Set the filename for the downloaded file
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //   }
  //   // pdfExportComponent.current.save();
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.GeneratedWrapper>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title="DOP"
                breadcrumbItem="Status: Report Generated"
              />
              <div className="back d-print-none" onClick={() => goMachine()}>
                <i className="dripicons-arrow-thin-left"></i>
                <span>Back</span>
              </div>
              {/* <Select
                defaultValue={layoutSelection}
                onChange={(e) => setLayoutSelection(e)}
                style={{ marginLeft: "calc( 50% - 200px )", width: 200 }}
              >
                <Option value="size-a4">A4</Option>
                <Option value="size-letter">Letter</Option>
                <Option value="size-executive">Execution</Option>
              </Select> */}

              <div className="table-responsive" style={{ paddingBottom: 15 }}>
                {Array.from({ length: pdfPageNumber }, (_, i) => (
                  <div key={i} className={`pdf-page ${layoutSelection}`}>
                    <Pdf
                      companyInfo={companyInfo}
                      editData={editData}
                      result={result}
                      reportData={reportData}
                      index={i}
                      pageLength={pdfPageNumber}
                    />
                  </div>
                ))}
              </div>

              <footer
                className="d-print-none"
                style={{ textAlign: "center", marginTop: 30 }}
              >
                <Button
                  disabled={invoiceButtonLoading}
                  // onClick={() => sendReport()}
                  onClick={() => setIsModalVisible(true)}
                >
                  Send Report
                </Button>
                {/* <Button onClick={() => printInvoice()}>Print Report</Button> */}
                {/* <Button onClick={handleExportWithComponent}>
                  Download PDF
                </Button> */}
              </footer>
            </Container>
            <SuccessModal
              title="Successfully!"
              content="Your sent report to customer"
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
            />
            <ST.StyleModal
              title="Edit"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={sendReport}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  // initialValues={{ email: editUserData?.email }}
                >
                  <h3>Report</h3>
                  <hr />
                  <Form.Item
                    name="technician_email"
                    label="Technician:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Send copies of reports to:"
                    name="copy_reports_user"
                  >
                    <Select
                      showSearch
                      className="antdSelect"
                      defaultValue="N/A"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option.title
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {companyUsers &&
                        companyUsers.map((res) => (
                          <Option
                            key={[res.email]}
                            title={`${res.firstname} ${res.lastname}`}
                            value={res.id}
                            disabled={res.leave}
                          >
                            {res.firstname} {res.lastname}
                          </Option>
                        ))}
                      <Option value={null}>N/A</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="customer_email"
                    label="Customer:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() =>
                        setOtherContactStatus(!otherContactStatus)
                      }
                      checked={otherContactStatus}
                    >
                      Other customer contacts:
                    </Checkbox>
                  </Form.Item>
                  {otherContactStatus && (
                    <Form.Item name="contact_emails" label="Contacts:">
                      <Select
                        mode="multiple"
                        size="middle"
                        placeholder="Please select"
                        style={{
                          width: "100%",
                        }}
                      >
                        {editData?.contacts &&
                          editData.contacts.map((res, index) => (
                            <Option key={index} value={res.contact_email}>
                              {res.contact_email +
                                " (" +
                                res.contact_firstname +
                                "  " +
                                res.contact_lastname +
                                ")"}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() => setIsOthers(!isOthers)}
                      checked={isOthers}
                    >
                      To other:
                    </Checkbox>
                  </Form.Item>
                  {isOthers && (
                    <Form.Item
                      name="other_email"
                      label="Other:"
                      rules={[
                        // { required: true, message: "This field is required" },
                        {
                          validator(_, value) {
                            if (value && !value.match(validRegex)) {
                              return Promise.reject("Invalid email address!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          height: "38px",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <AntdBtn
                      htmlType="button"
                      onClick={() => setIsModalVisible(false)}
                    >
                      Cancel
                    </AntdBtn>
                    <AntdBtn
                      type="primary"
                      htmlType="submit"
                      disabled={invoiceButtonLoading}
                    >
                      Send
                    </AntdBtn>
                  </Form.Item>
                </Form>
              </p>
            </ST.StyleModal>
          </ST.GeneratedWrapper>
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;

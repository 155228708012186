import { del, get, post, put } from "../helpers/api_helper"

export const SendReport = data => post('/report/sendReport', data)
export const getReportByUser = data => post('/report/getReportByUser', data)
export const getUserById = data => post('/report/getUserById', data)

export const getRepotByProject = data => get(`/report/getByProject/${data.id}`)

export const getReportById = data => get(`/report/getReportById/${data.id}`)

export const makePdf = data => get(`report/makePdf`)


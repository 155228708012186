export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_ADMIN = "LOGIN_ADMIN"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const VERIFY_USER = 'VERIFY_USER'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAILED = 'VERIFY_FAILED'

export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'
export const CHANGE_COMPANY_INFO = 'CHANGE_COMPANY_INFO'

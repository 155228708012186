/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import MetaTags from 'react-meta-tags';

//import images
import logo from '../../assets/images/logo-sm-full.png'
import flowIcon from "../../assets/images/home/flowIcon.png";
import * as ST from './styles'

const PageMaintenance = props => {
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    setFormLoaded(true);
  }, []);

  return (
    <ST.Wrapper>
      <MetaTags>
        <title>Home | DOP Test Network</title>
      </MetaTags>
      <header>
        <div className='containerFix'>
          <div className='row'>
            <div className='col-md-4 logoSection'>
              <Link to="/dashboard" className="">
                <img src={logo} alt='' />
              </Link>
            </div>
            <div className='col-md-8 linkSection'>
              <Link to="/homepage" className="">
                <span>Home</span>
              </Link>
              <Link to="/contact" className="">
                <span>Contact</span>
              </Link>
              <Link to="/login" className="">
                <span>Login</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div>
        {formLoaded ? (
          <iframe
            src="https://api.leadconnectorhq.com/widget/form/kBMQMOJKzu0gwpDPQRYh"
            style={{ width: '100%', height: '100%', border: 'none', borderRadius: '4px' }}
            id="inline-kBMQMOJKzu0gwpDPQRYh"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="DOP Test Network Lead"
            data-height="683"
            data-layout-iframe-id="inline-kBMQMOJKzu0gwpDPQRYh"
            data-form-id="kBMQMOJKzu0gwpDPQRYh"
            title="DOP Test Network Lead"
          >
          </iframe>
        ) : (
          <Spin tip="Loading..." />
        )}
      </div>
    </ST.Wrapper>
  )
}

export default PageMaintenance;
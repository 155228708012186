/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
// import { PDFExport } from  '@progress/kendo-react-pdf'

//import Breadcrumbs
import { Button, Select } from "antd";
import moment from "moment";
import { getInvoiceById } from "../../data/invoice";
// import {
//   Table
// } from "reactstrap";
import * as ST from "../Invoices/styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import Pdf from "../Invoices/pdf.js";

const { Option } = Select;
const PdfInvoice = () => {
  // const pdfExportComponent = useRef(null)
  const [editData, setEditData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [discountStatus, setDiscountStatus] = useState(true);
  const [discountValue, setDiscountValue] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const { id } = useParams();

  const { result } = getMachines({ project_id: editData?.project_id });

  useEffect(() => {
    getInvoiceById({ id: id }).then((res) => {
      if (res?.length) {
        var params = {
          customer_email: res[0].customer_email,
          customer_name: res[0].customer_name,
          customer_phone: res[0].customer_phone,
          contact_name: res[0].contact_name,
          contacts: JSON.parse(res[0].contacts) || [],
          project_name: res[0].project_name,
          customer_id: res[0].customer_id,
          ses_project_id: res[0].ses_project_id,
          reference_id: res[0].reference_id,
          project_city: res[0].project_city,
          project_state: res[0].project_state,
          project_street: res[0].project_street,
          project_zip: res[0].project_zip,
          project_id: res[0].project_id,
          invoice_date: moment(res[0].invoice_date).format("MM/DD/YYYY"),
          city: res[0].city,
          state: res[0].state,
          street: res[0].street,
          zip: res[0].zip,
          discount: res[0].Discount,
          terms: res[0].terms,
          extra_test_num: result?.length < 4 ? 0 : result?.length - 4,
          invoice_id: res[0].invoice_id,
          ID: res[0].ID,
          initial_rate: res[0].initial_rate,
          additional_rate: res[0].additional_rate,
          copy_invoices_user: res[0].copy_invoices_user,
          company_id: res[0].company_id,
          job_number: res[0].job_number,
        };
        setEditData(params);

        if (
          res[0].Discount &&
          res[0].Discount !== "0" &&
          res[0].Discount !== "N/A"
        ) {
          setDiscountStatus(false);
          setDiscountValue(res[0].Discount);
        } else {
          setDiscountStatus(true);
        }
      }
    });
  }, [id, result]);

  useEffect(() => {
    getCompanyInfo({ id: editData?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
      }
    });
  }, [editData]);

  const handleExportWithComponent = async (event) => {
    try {
      const pdfContent =
        document.getElementsByClassName("pdf-page")[0].innerHTML;
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/report/makePdfInvoice",
        {
          method: "POST",
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: pdfContent }),
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "example.pdf"; // Set the filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // pdfExportComponent.current.save();
  };
  return (
    <>
      <MetaTags>
        <title>Project/Invoice | DOP Test Network</title>
      </MetaTags>
      {editData && (
        <ST.GeneratedWrapper>
          <Select
            defaultValue={layoutSelection}
            onChange={(e) => setLayoutSelection(e)}
            style={{
              marginLeft: "calc( 50% - 200px + 100px )",
              width: 200,
              marginTop: 30,
            }}
          >
            <Option value="size-a4">A4</Option>
            <Option value="size-letter">Letter</Option>
            <Option value="size-executive">Execution</Option>
          </Select>
          {/* <PDFExport  ref={pdfExportComponent}>
            <div className={ `pdf-page ${ layoutSelection }` }>
              <Row className='panelFirst' style={{ paddingBottom: 5, borderBottom: '3px solid' }}>
                <Col span={12}>
                  <div style={{ minHeight: 70 }}>
                    <img src={`${process.env.REACT_APP_URL}/company-logo/${companyInfo?.logo}`} height='70' alt='' />
                  </div>
                </Col>
                <Col span={12} style={{ fontSize: 8, display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap', justifyContent: 'right', fontFamily: 'Arial' }}>
                  {companyInfo?.company_slogan}
                </Col>
              </Row>
              <div style={{ textAlign: 'center', margin: 10, fontWeight: '600' }}>
                DOP AEROSOL TEST CERTIFICATION INVOICE
              </div>
              <Row className='title' gutter={[8, 8]}>
                <Col span={8}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} style={{ textWrap: 'nowrap' }}>
                      Invoice Date: {editData?.invoice_date?.split(' ')[0]}
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} style={{ textWrap: 'nowrap' }}>
                      Invoice No.: {companyInfo?.invoice_start_prefix ? companyInfo.invoice_start_prefix + '-' + editData?.invoice_id : editData?.invoice_id}
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} style={{ textWrap: 'nowrap' }}>
                      Job No.: {companyInfo?.invoice_start_prefix ? companyInfo.invoice_start_prefix + '-' + editData?.ses_project_id : editData?.ses_project_id}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='panel' gutter={[8, 8]}>
                <Col span={12}>
                  <section>
                    <div>Client:</div>
                    <div>{editData?.customer_name}</div>
                    <div>{editData?.street}</div>
                    <div>{editData?.city}, {editData?.state}</div>
                  </section>
                </Col>
                <Col span={12}>
                  <section>
                    <div>Project Information:</div>
                    <div>{editData?.project_name}</div>
                    <div>{editData?.project_street}</div>
                    <div>{editData?.project_city}, {editData?.project_state}</div>
                    <div>Client Reference No.: {editData?.reference_id}</div>
                  </section>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <th width='60%'>DESCRIPTION</th>
                      <th>QTY</th>
                      <th>RATE</th>
                      <th>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>EQUIPMENT TESTING-4 units</td>
                      <td>1</td>
                      <td>{editData?.initial_rate}</td>
                      <td>{editData?.initial_rate?.toFixed(2)}</td>
                    </tr>
                    {editData?.extra_test_num !== 0 && (
                      <tr>
                        <td>Extra units tested</td>
                        <td>{editData?.extra_test_num}</td>
                        <td>{editData?.additional_rate}</td>
                        <td>{(editData?.extra_test_num * editData?.additional_rate).toFixed(2)}</td>
                      </tr>
                    )}
                    <tr>
                      <td className='colorNone'>'</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='colorNone'>'</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className='colorNone'>'</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className='grayTr'>
                      <td></td>
                      <td colspan='2'>SUBTOTAL</td>
                      <td>{(editData?.initial_rate + editData?.extra_test_num * editData?.additional_rate).toFixed(2)}</td>
                    </tr>
                    {!discountStatus && (
                      <tr className='grayTr'>
                        <td></td>
                        <td colspan='2'>DISCOUNT {discountValue ? `${discountValue}%` : ''}</td>
                        <td>{discountValue ? ((editData?.initial_rate + editData?.extra_test_num * editData?.additional_rate) * (discountValue / 100)).toFixed(2) : 0}</td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td colspan='2'>INVOICE TOTAL</td>
                      <td>
                        {!discountStatus && discountValue ? ((editData?.initial_rate + editData?.extra_test_num * editData?.additional_rate) - (editData?.initial_rate + editData?.extra_test_num * editData?.additional_rate) * (discountValue / 100)).toFixed(2) : (editData?.initial_rate + editData?.extra_test_num * editData?.additional_rate).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20  }}>
                  <span>{companyInfo?.street}, {companyInfo?.city}, {companyInfo?.state} {companyInfo?.zip}</span>
                  <span style={{ marginLeft: 40 }}>{companyInfo?.phone}</span>
                </div>
              </div>
              <div style={{ position: 'absolute', width: 'calc(100% - 0.6in)', bottom: '0.4in' }}>
                <Row>
                  <Col span={12}>
                    <div style={{ fontSize: 8, border: '1px solid', textAlign: 'center', padding: 5, fontFamily: 'Arial' }}>
                      <div style={{ marginBottom: 10 }}>Remit Payment to:</div>
                      <div>{companyInfo?.company_name}</div>
                      <div>{companyInfo?.street}</div>
                      <div>{companyInfo?.city}, {companyInfo?.state}, {companyInfo?.zip}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </PDFExport> */}

          <div className={`pdf-page ${layoutSelection}`}>
            <Pdf
              companyInfo={companyInfo}
              editData={editData}
              result={result}
              discountValue={discountValue}
              discountStatus={discountStatus}
            />
          </div>
          <div style={{ textAlign: "center", paddingBottom: 50 }}>
            <Button type="primary" danger onClick={handleExportWithComponent}>
              Download PDF
            </Button>
          </div>
        </ST.GeneratedWrapper>
      )}
    </>
  );
};

export default PdfInvoice;

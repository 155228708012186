/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux"
import { useHistory, useParams } from 'react-router-dom'
import { Table, Select, Button, Spin, Input, Space, Tag, Divider, Row, Col, Checkbox, Radio } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import { companyDelete, getCompany, companyApproveState, BackupDatabase, getCompanyInfo, getCompanyDetail, updateCompanyPrice, getCompanyOfOwner, AddTrialDays, ChangeActiveStatus, ChangeOwnerPassword, GetPrices } from '../../data/comany'
import { sendEmail } from '../../data/user'
import { SeeLastNextPaymentDate } from '../../data/invoice'
import { openNotificationWithIcon } from '../../components/Modal/notification'

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container
} from "reactstrap";
import * as ST from './styles'

const { Option } = Select
const Invoices = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [companyInfo, setCompanyInfo] = useState()
  const [paymentDate, setPaymentDate] = useState()
  const [items, setItems] = useState(['Default(100 * 75)'])
  const [name, setName] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [companyPrice, setCompanyPrice] = useState()
  const [sendEmailModal, setSendEmailModal] = useState(false)
  const [sendEmailButton, setSendEmailButton] = useState(false)
  const [companyOfOwner, setCompanyOfOwner] = useState()
  const [addTrialDays, setAddTrialDays] = useState(30)
  const [statusChecked, setStatusChecked] = useState(false)
  const [trialStatus, setTrialStatus] = useState()
  const [ownerPassword, setOwnerPassword] = useState()
  const [changePasswordFlag, setChangePasswordFlag] = useState(true)
  const [productPrices, setProductPrices] = useState()
  const [companyDetail, setCompanyDetail] = useState()

  const { currentUser } = useSelector(state => ({
    currentUser: state.Login.user
  }))

  useEffect(() => {
    // setLoading(true)
    if (companyInfo && companyOfOwner) {
      SeeLastNextPaymentDate({ subscriptionId: companyInfo.session_id, email: companyOfOwner.email }).then(res => {
        setPaymentDate(res)
        setLoading(false)
      })
      setCompanyPrice(companyInfo?.price)
    }
  }, [companyInfo, companyOfOwner])

  useEffect(() => {
    GetPrices().then(res => {
      setProductPrices(res)
    })
  }, [])

  useEffect(() => {
    if (id) {
      getCompanyInfo({ id: id }).then(res => {
        setCompanyInfo(res[0])
        setLoading(false)
      })
      getCompanyOfOwner({ id: id }).then(res => {
        setCompanyOfOwner(res[0])
      })

      getCompanyDetail({ id: id }).then(res => {
        setCompanyDetail(res)
      })
    }
  }, [id])

  const descriptionFun = e => {
    if (e === "paid") {
      return (<Tag icon={<CheckCircleOutlined />} color="success">
          Succeeded
      </Tag>)
    } else if (e === 'open') {
      return (<Tag icon={<ClockCircleOutlined />} color="warning">
        Incomplete
      </Tag>)
    } else if (e === 'canceled') {
      return (<Tag icon={<CloseCircleOutlined />} color="error">
        Canceled
      </Tag>)
    } else {
      return (
        <Tag icon={<MinusCircleOutlined />} color="default">
          Stop
        </Tag>)
    }
  }

  const changeDate = e => {
    const date = new Date(e * 1000); // Convert Unix timestamp to milliseconds

    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true 
    };

    const formattedDate = date.toLocaleString('en-US', options);

    return <>{formattedDate}</>
  }

  const subscriptionStatus = e => {
    if (!e?.trialEndDate) {
      return (<Tag icon={<MinusCircleOutlined />} color="default">
        N/A
      </Tag>)
    }
    if (e?.trialEndDate && e?.trialEndDate > Math.floor(Date.now() / 1000)) {
      return (
        <>
          <Tag icon={<ClockCircleOutlined />} color="warning">
            Trial(Active)
          </Tag>
          <span>
            {Math.ceil((e?.trialEndDate - Math.floor(Date.now() / 1000)) / (60 * 60 * 24))} days left
          </span>
        </>
      )
    }
    if (e?.trialEndDate && e?.trialEndDate < Math.floor(Date.now() / 1000) && !e?.session_id) {
      return (<Tag icon={<CloseCircleOutlined />} color="error">
        Trial Lapsed
      </Tag>)
    }
    if (e?.session_id) {
      return (<Tag icon={<CheckCircleOutlined />} color="success">
          Subscribed (Active) {e?.cancelSubscriptionStatus && 'Subscription Canceled'}
      </Tag>)
    }
  }

  const subscriptionSelect = e => {
    if (!e?.trialEndDate) {
      return "N/A"
    }
    if (e?.trialEndDate && e?.trialEndDate > Math.floor(Date.now() / 1000)) {
      return "Trial"
    }
    if (e?.trialEndDate && e?.trialEndDate < Math.floor(Date.now() / 1000) && !e?.session_id) {
      return "Trial Lapsed"
    }
    if (e?.session_id) {
      return "Subscribed"
    }
  }
  const changeDateSting = e => {
    // Convert Unix timestamp to a JavaScript Date object using moment
    const dateObject = moment.unix(e);

    // Format the date in the desired format "MM/DD/YYYY"
    return dateObject.format('MM/DD/YYYY');
  }
  const columns = [
    {
      title: 'Amount',
      key: 'amount_due',
      render: data => {
        return <>${data?.amount_due / 100}</>
      }
    },
    {
      title: '',
      key: 'currency',
      dataIndex: 'currency'
    },
    {
      title: '',
      key: 'status',
      render: data=> {
        return <div className='decriptionLabel'>{descriptionFun(data?.status)}</div>
      }
    },
    {
      title: 'Description',
      key: 'billing_reason',
      dataIndex: 'billing_reason'
    },
    {
      title: 'Customer',
      key: 'customer_email',
      render: data => {
        return <>{data?.customer_email}</>
      }
    },
    {
      title: 'Date',
      key: 'created',
      render: data => {
        return changeDateSting(data?.created)
      }
    }
  ]

  const addItem = (e) => {
    if (name && width && height) {
      e.preventDefault();
      setItems([...items, `${name} (${width} * ${height})` || `New item ${index++}`]);
      setName('')
      setWidth('')
      setHeight('')
    }
  };

  const upgradeCompanyPrice = (e) => {
    // var con = confirm("Are you sure to update this subscription price?")
    // if (con) {
      setLoading(true)
      updateCompanyPrice({ id: id, price: e }).then(() => {
        // console.log()
        setLoading(false)
        setCompanyPrice(e)
        // changeActiveStatus(e)
      })
    // }
  }

  const sendEmailToUser = () => {
    setSendEmailButton(true)
    sendEmail({ email: companyOfOwner.email }).then(res => {
      setSendEmailModal(false);
      openNotificationWithIcon('success', 'Note', 'Sent reset password email to ' + companyOfOwner.email + ' successfully')
      setSendEmailButton(false)
    }).catch(err => {
      openNotificationWithIcon('error', 'Note', 'Failed')
      setSendEmailButton(false)
    })
  }

  const addTrialDay = () => {
    var con = confirm("Are you sure to add this trial days additionally?")
    if (con) {
      setLoading(true)
      var trail = addTrialDays
      if (companyInfo?.trialEndDate && Math.ceil((companyInfo?.trialEndDate - Math.floor(Date.now() / 1000)) / (60 * 60 * 24)) > 0 ) {
        trail = Number(Math.ceil((companyInfo?.trialEndDate - Math.floor(Date.now() / 1000)) / (60 * 60 * 24))) + Number(addTrialDays)
      }
      
      AddTrialDays({ id: id, trial: Math.floor(Date.now() / 1000) + (trail * 24 * 60 * 60) }).then(res => {
        openNotificationWithIcon('success', 'Note', 'Set additional trial days successfully')

        setLoading(false)
      })
    }
  }

  const changeActiveStatus = (e) => {
    setLoading(true)
    ChangeActiveStatus({ id: id, status: e, session_id: companyInfo?.session_id }).then(res => {
      // setLoading(false)
      setTrialStatus(e)
      getCompanyInfo({ id: id }).then(res => {
        setCompanyInfo(res[0])
        setLoading(false)
      })
      openNotificationWithIcon('success', 'Note', 'Was changed subscription status to ' + e + ' successfully')
    })
  }

  const changeOwnerPassword = () => {
    if (!ownerPassword) {
      openNotificationWithIcon('error', 'Note', 'Input password')
      return 
    }
    var con = confirm("Are you sure to change the password of this company's owner(" + companyOfOwner.email + ")?")
    if (con) {
      setLoading(true)
      ChangeOwnerPassword({ email: companyOfOwner.email, password: ownerPassword }).then(res => {
        setLoading(false)
        openNotificationWithIcon('success', 'Note', 'Was updated the password of this company`s owner(' + companyOfOwner.email + ') to ' + ownerPassword + ' successfully')
      })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | DOP Test Network</title>
        </MetaTags>
        <ST.CompanyWrapper>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="DOP" breadcrumbItem={companyInfo?.company_name} />
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin tip="Loading..." />
              </div>
            ) : (
              <>
                <div class="grid-container">
                  <div class="grid-item">Customers: {companyDetail?.customers.length}</div>
                  <div class="grid-item">Projects: {companyDetail?.projects.length}</div>
                  <div class="grid-item">Invoices: {companyDetail?.invoices.length}</div>  
                  <div class="grid-item">Reports: {companyDetail?.reports.length}</div> 
                </div>
                <div className='row'>
                  <div className='col-md-6' style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <b>Subscription status:</b> <div className='decriptionLabel'>{subscriptionStatus(companyInfo)}</div>
                  </div>
                  <div className='col-md-6' style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <b>Status Manage: <Checkbox checked={statusChecked} onChange={() => setStatusChecked(!statusChecked)}>Enable</Checkbox>(only test mode)</b>
                    <div className='decriptionLabel'>
                      <Select style={{ width: 200 }} defaultValue={subscriptionSelect(companyInfo)} value={trialStatus} disabled={!statusChecked} onChange={(e) => changeActiveStatus(e)}>
                        <Option value='N/A'>N/A</Option>
                        <Option value='Trial'>Trial (Active)</Option>
                        <Option value='Trial Lapsed'>Trial Lapsed</Option>
                        <Option value='Subscribed'>Subscribed (Active)</Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {/* Price: <Input defaultValue={100} size='small' /> <span>Default</span> <Button>Update</Button> */}
                    <span style={{ marginRight: 20 }}><b>Extend Trial:</b> </span> 
                    <Space direction="horizontal">
                      <span>
                        Add
                      </span>
                      <Input
                        value={addTrialDays}
                        onChange={e => setAddTrialDays(e.target.value)}
                        type='number'
                        disabled={companyInfo?.session_id}
                      />
                      <span>
                        days
                      </span>
                      <Button style={{ width: 80 }} onClick={() => addTrialDay()} disabled={companyInfo?.session_id}>
                      Add
                      </Button>
                    </Space>
                  </div>
                  <div className='col-md-6'>
                    {/* Price: <Input defaultValue={100} size='small' /> <span>Default</span> <Button>Update</Button> */}
                    <span style={{ marginRight: 20 }}><b>Price:</b> </span> 
                    <Space direction="horizontal">
                      {/* <Input
                        defaultValue={companyInfo?.price}
                        value={companyPrice}
                        onChange={e => setCompanyPrice(e.target.value)}
                        type='number'
                      />
                      <Button style={{ width: 80 }} onClick={() => upgradeCompanyPrice()}>
                      Update
                      </Button> */}
                      <Select style={{ width: 200 }} defaultValue={companyInfo?.price} value={companyPrice} onChange={(e) => upgradeCompanyPrice(e)}>
                        {productPrices && productPrices.map(res => (
                          <Option value={res} key={res}>${res}</Option>
                        ))}
                      </Select>
                    </Space>
                  </div>
                </div>
                <div>
                <div className='row'>
                  <div className='col-md-6'>
                    <b>Creation Date:</b> {moment(companyOfOwner?.created_at).format('MM/DD/YYYY')}
                  </div>
                  <div className='col-md-6'>
                    <b>Last Login Date:</b> {companyOfOwner?.login_at ? moment(companyOfOwner?.login_at).format('MM/DD/YYYY') : ''}
                  </div>
                </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Button onClick={() => setSendEmailModal(true)} style={{ marginRight: 20 }}>Admin reset password</Button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <b>Payment history:</b>
                    {/* {companyInfo?.session_id && ( */}
                      <div className='row' style={{ marginTop: 15 }}>
                        <div className='col-md-4'>
                          <b>Subscription Started:</b> {paymentDate?.history?.length ? changeDateSting(paymentDate?.history[paymentDate.history.length - 1].created) : 'N/A'}
                        </div>
                        <div className='col-md-4'>
                          <b>Last Payment:</b> {paymentDate?.lastPaymentDate ? moment(paymentDate?.lastPaymentDate).format('MM/DD/YYYY') : 'N/A'}
                        </div>
                        <div className='col-md-4'>
                          <b>Next Payment:</b> {paymentDate?.nextPaymentDate ? moment(paymentDate?.nextPaymentDate).format('MM/DD/YYYY') : 'N/A'}
                        </div>
                      </div>
                    {/* )} */}
                    <div className="table-responsive">
                      <Table dataSource={paymentDate?.history} columns={columns} style={{ marginTop: 25 }} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div>
                      <b>Label selection:</b>
                    </div>
                    <Space direction="horizontal" style={{ marginTop: 15 }}>
                      <Select
                        mode="tags"
                        style={{
                          width: 500,
                        }}
                        placeholder="custom dropdown render"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                            <div
                              style={{
                                padding: '0 8px 4px',
                              }}
                            >
                              <div className='row'>
                                <div className='col-md-6'>
                                  <Input
                                    placeholder="Please enter label name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    onKeyDown={(e) => e.stopPropagation()}
                                  />
                                </div>
                                <div className='col-md-6'>
                                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                    Add Label Type
                                  </Button>
                                </div>
                              </div>
                              <div className='row' style={{ marginTop: 5 }}>
                                <div className='col-md-6'>
                                  <Input
                                    placeholder="Please enter lable width(mm)"
                                    value={width}
                                    onChange={(e) => setWidth(e.target.value)}
                                    onKeyDown={(e) => e.stopPropagation()}
                                  />
                                </div>
                                <div className='col-md-6' />
                              </div>
                              <div className='row' style={{ marginTop: 5 }}>
                                <div className='col-md-6'>
                                  <Input
                                    placeholder="Please enter label height(mm)"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                    onKeyDown={(e) => e.stopPropagation()}
                                  />
                                </div>
                                <div className='col-md-6' />
                              </div>
                            </div>
                          </>
                        )}
                        options={items.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                      <Button style={{ width: 80 }} >
                      Update
                      </Button>
                    </Space>
                  </div>
                </div>
              </>
            )}
          </Container>
          
          <ST.StyleModal title="Edit" visible={sendEmailModal}  onCancel={() => setSendEmailModal(false)}>
            <h4 style={{ textAlign: 'center' }}>Are you sure to upgrade the password of this company's owner ({companyOfOwner?.email})?</h4>
            <Radio.Group defaultValue={1} onChange={(e) => setChangePasswordFlag(e.target.value == 1 ? true : false)}>
              <Space direction="vertical">
                <Radio value={1}>Send the reset password email</Radio>
                <Radio value={2}>Change the password manually</Radio>
                {!changePasswordFlag && (
                  <Input
                    value={ownerPassword}
                    onChange={e => setOwnerPassword(e.target.value)}
                    style={{ marginLeft: 25 }}
                  />
                )}
              </Space>
            </Radio.Group>

            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <Button htmlType="button" onClick={() => setSendEmailModal(false)}>
                Cancel
              </Button>
              {changePasswordFlag ? (
                <Button type="primary" disabled={sendEmailButton} onClick={() => sendEmailToUser()} style={{ marginLeft: 20 }}>
                  Send
                </Button>
              ) : (
                <Button type="primary" onClick={() => changeOwnerPassword()} style={{ marginLeft: 20 }}>
                  Change Password
                </Button>
              )}
            </div>
          </ST.StyleModal>
        </ST.CompanyWrapper>
      </div>
    </React.Fragment>
  )
}

export default Invoices
/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import ComfirmModal from "../../components/Modal/comfirmModal";
import SuccessModal from "../../components/Modal/successModal";
// import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import { updateCompany, getCompanyInfo } from "../../data/comany";
import { getAdmins } from "../../data/user";
import { inviteUser } from "../../data/notificate_invite";
import ImgCrop from "antd-img-crop";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import moment from "moment";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Upload,
  Modal,
  Select,
  Checkbox,
  Radio,
  Space,
  Alert,
} from "antd";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Container } from "reactstrap";
import * as ST from "./styles";
import { openNotificationWithIcon } from "../../components/Modal/notification";
// import AWS from 'aws-sdk'
import { getRoles } from "../../data/role";
import {
  getAccount,
  CreateSubscription,
  CancelSubscription,
  TrialStart,
  UpdateSubscription,
  SeeRenewalDate,
  Resubscribe,
} from "../../data/invoice";
import { getUsersByCompanyId } from "../../data/user";
import usStates from "../../constants/usStates.js";

// AWS.config.update({
//   accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
// })
// const myBucket = new AWS.S3({
//   params: { Bucket: process.env.REACT_APP_BUCKET_NAME},
//   region: process.env.REACT_APP_REGION
// })

const { Option } = Select;
// const { TextArea } = Input
const discountOptions = [
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "15%", value: "15" },
  { label: "20%", value: "20" },
];
const CARD_ELEMENT_OPTIONS = {
  // For more styling details, see https://stripe.com/docs/js/appendix/style
  style: {
    base: {
      fontSize: 16,
      color: "#424770",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const validRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const Company = () => {
  // const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [modal_center1, setmodal_center1] = useState(false);
  const [companyAdmins, setCompanyAdmins] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [file1List, setFile1List] = useState([]);
  const [discountStatus, setDiscountStatus] = useState(false);
  const [discountSelect, setDiscountSelect] = useState("enabled");
  const [count, setCount] = useState(0);
  const [companyUsers, setCompanyUsers] = useState();
  const [roleList, setLoleList] = useState([]);
  const [sendButton, setSendButton] = useState(false);
  const [subscriptionModal, SetSubscriptionModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState();
  const [changePaymentMethodFlag, setChangePaymentMethodFlag] = useState(false);
  const [seeRenewalDate, setSeeRenewalDate] = useState();
  const [cancelSubscriptionDate, setCancelSubscriptionDate] = useState();
  const [radioValue, setRadioValue] = useState(1);
  // const [trialEndDate, setTrialEndDate] = useState()

  // const [subscriptionLevel, setSubscriptionLevel] = useState()
  // const [bankCreateButtonStatus, SetBankCreateButtonStatus] = useState(false)
  // const [bankCheck, setBankCheck] = useState(false)
  const [companyInfo, setCompanyInfo] = useState();
  const [payProgress, setPayProgress] = useState(false);
  const [error, setError] = useState("");

  const { currentUser } = useSelector((state) => ({
    currentUser: state.Login.user,
  }));

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const [form] = Form.useForm();
  const [formStripe] = Form.useForm();
  const validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const websiteCheck = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  const phoneCheck = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  const numberCheck = /^\d*\.?\d*$/;

  useEffect(() => {
    getRoles().then((res) => {
      setLoleList(res);
    });
  }, []);

  useEffect(() => {
    if (currentUser?.company_id) {
      getUsersByCompanyId({ company_id: currentUser?.company_id }).then(
        (res) => {
          setCompanyUsers(
            res?.filter((e) => e?.firstname !== "" && e?.lastname !== "")
          );
        }
      );
    } else {
      setCompanyUsers([]);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.role_id === 2 && currentUser?.company_id) {
      // requestCheck({ create_user_id: currentUser?.sub }).then(res => {
      getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
        if (res.length && res[0].status) {
          const company_info = res[0];
          if (!res[0].discount) {
            setDiscountSelect("disabled");
            setDiscountStatus(true);
          } else {
            company_info.discount = company_info?.discount?.split(",");
          }
          if (company_info.logo) {
            setFileList([
              {
                uid: "-1",
                name: "image.png",
                status: "done",
                // url: `https://s3.amazonaws.com/${process.env.REACT_APP_BUCKET_NAME}/${company_info.logo}`,
                url: `${process.env.REACT_APP_URL}/company-logo/${company_info.logo}`,
              },
            ]);
          }
          if (company_info.black_white_logo) {
            setFile1List([
              {
                uid: "-1",
                name: "image.png",
                status: "done",
                url: `${process.env.REACT_APP_URL}/company-logo/${company_info.black_white_logo}`,
              },
            ]);
          }
          form.setFieldsValue(company_info);

          // setSubscriptionLevel(company_info.subscription_level)
          // if (company_info.bank_account) {
          //   setBankCheck(true)
          // }
        }
        setCompanyInfo(res[0]);

        if (res[0]?.cancelSubscriptionStatus) {
          CancelSubscription({ subscriptionId: res[0]?.session_id }).then(
            (res) => {
              // Handle the cancellation information
              if (res.cancel_at_period_end) {
                // Subscription will be canceled at the end of the current billing period
                const cancellationDate = new Date(res.cancel_at * 1000); // Convert timestamp to date
                setCancelSubscriptionDate(cancellationDate.toDateString());
              } else {
                // Subscription has been immediately canceled
                const canceledDate = new Date(res.canceled_at * 1000); // Convert timestamp to date
                setCancelSubscriptionDate(canceledDate.toDateString());
                updateCompany({
                  id: currentUser.company_id,
                  session_id: "",
                  customer_id: "",
                  cancelSubscriptionStatus: false,
                }).then(() => {
                  setCompanyInfo({
                    ...companyInfo,
                    session_id: "",
                    customer_id: "",
                    cancelSubscriptionStatus: false,
                  });
                });
              }
              // setSubscriptionLevel(query.get('subscription'))
            }
          );
        }
      });
    }
    getAdmins({ company_id: currentUser?.company_id }).then((res) => {
      setCompanyAdmins(res);
    });
  }, []);

  useEffect(async () => {
    if (companyInfo) {
      // SeeRenewalDate({ subscriptionId: companyInfo?.session_id }).then(
      //   (res) => {
      //     setSeeRenewalDate(res);
      //   }
      // );
      try {
        const res = await SeeRenewalDate({
          subscriptionId: companyInfo?.session_id,
        });
        setSeeRenewalDate(res);
      } catch (error) {
        console.error("Error fetching renewal date:", error);
      }
    }
  }, [companyInfo]);
  // useEffect(() => {
  //   if (companySubscriptionId) {
  //     SeeTrialEndDate({ subscriptionId: companySubscriptionId }).then(res => {
  //       const trialEndDate = new Date(res.trialEnd * 1000); // Convert Unix timestamp to JavaScript Date object

  //       if (res.status !== "trialing") {
  //         setTrialEndDate('Trial Lapsed')
  //       } else {
  //         setTrialEndDate(moment(trialEndDate)?.format('MM/DD/YYYY'))
  //       }
  //     })
  //   }
  // }, [companySubscriptionId])

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const tog_center1 = () => {
    setmodal_center1(!modal_center1);
  };

  const handleSubmit = (val) => {
    const data = val;
    if (discountStatus || !data.discount || data.discount.length === 0) {
      delete data.discount;
    }
    if (!data.initial_rate) {
      data.initial_rate = 0;
    }
    if (!data.additional_rate) {
      data.additional_rate = 0;
    }
    if (!data.dop_certificate_start) {
      data.dop_certificate_start = 0;
    }
    if (!data.invoice_start) {
      data.invoice_start = 0;
    }
    data.id = currentUser.company_id;

    updateCompany(data).then((res) => {
      setmodal_center1(true);
    });
  };

  const addUser = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    setSendButton(true);
    inviteUser({
      company_id: currentUser.company_id,
      inviter: currentUser.sub,
      requester_email: values.email,
      inviter_email: currentUser.email,
      message: values.message,
      role_id: values.role_id,
    })
      .then((res) => {
        if (res?.message === "success") {
          openNotificationWithIcon(
            "success",
            "Note",
            "Sent your invitation successfully"
          );
          setIsModalVisible(false);
        } else if (res?.message === "stuff") {
          openNotificationWithIcon(
            "error",
            "Note",
            "Can`t send your invitation message because he is a stuff of company!"
          );
          setIsModalVisible(false);
          setSendButton(false);
        }
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Note", "Failed");
        setSendButton(false);
      });
  };

  //----------upload feature
  const handlePreviewCancel = () => setPreviewVisible(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setCount(count + 1);
    if (newFileList && newFileList.length) {
      const file = newFileList[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!["png", "jpg", "jpeg"].includes(fileExtension)) {
        openNotificationWithIcon(
          "error",
          "Note",
          "Invalid file type. Only PNG and JPG files are allowed."
        );
        return; // Exit function if file extension is not allowed
      }

      // Your upload logic here
      if (count === 2) {
        const fd = new FormData();
        fd.append("img", file.originFileObj);
        fetch(`${process.env.REACT_APP_API_URL}/upload`, {
          method: "POST",
          body: fd,
        })
          .then(async (response) => {
            const data = await response.json();
            form.setFieldsValue({ logo: data.filename });
          })
          .catch((error) => {
            console.log("There was an error!", error);
          });
        setCount(0);
      }
    } else {
      // Your deletion logic here
      if (fileList[0]?.url) {
        fetch(`${process.env.REACT_APP_API_URL}/delete`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            file: fileList[0].url.split(
              `${process.env.REACT_APP_URL}/company-logo/`
            )[1],
          }),
        })
          .then(async (response) => {
            const data = await response.json();
          })
          .catch((error) => {
            console.log("There was an error!", error);
          });
      }
      form.setFieldsValue({ logo: "" });
    }
    setFileList(newFileList);
  };

  const handleFile1Change = async ({ fileList: newFileList }) => {
    setCount(count + 1);
    if (newFileList && newFileList.length) {
      const file = newFileList[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!["png", "jpg", "jpeg"].includes(fileExtension)) {
        openNotificationWithIcon(
          "error",
          "Note",
          "Invalid file type. Only PNG and JPG files are allowed."
        );
        return; // Exit function if file extension is not allowed
      }

      // Your upload logic here
      if (count === 2) {
        const fd = new FormData();
        fd.append("img", file.originFileObj);
        fetch(`${process.env.REACT_APP_API_URL}/upload`, {
          method: "POST",
          body: fd,
        })
          .then(async (response) => {
            const data = await response.json();
            form.setFieldsValue({ black_white_logo: data.filename });
          })
          .catch((error) => {
            console.log("There was an error!", error);
          });
        setCount(0);
      }
    } else {
      // Your deletion logic here
      if (fileList[0]?.url) {
        fetch(`${process.env.REACT_APP_API_URL}/delete`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            file: fileList[0].url.split(
              `${process.env.REACT_APP_URL}/company-logo/`
            )[1],
          }),
        })
          .then(async (response) => {
            const data = await response.json();
          })
          .catch((error) => {
            console.log("There was an error!", error);
          });
      }
      form.setFieldsValue({ black_white_logo: "" });
    }
    setFile1List(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Company Logo
      </div>
    </div>
  );
  //----------upload feature

  const discountChange = (val) => {
    if (val === "enabled") {
      setDiscountStatus(false);
      setDiscountSelect("enabled");
    } else {
      setDiscountStatus(true);
      setDiscountSelect("disabled");
    }
  };

  // const createBankAccount = () => {
  //   SetBankCreateButtonStatus(true)
  //   getAccount().then(res => {
  //     window.location.href = res.url
  //     setVisibleModal(false)
  //     // SetBankCreateButtonStatus(false)
  //   })
  // }

  const createSubscription = async (val) => {
    setPayProgress(true);
    try {
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: val?.name,
          email: val?.email,
        },
      });

      // call the backend to create subscription
      const response = await CreateSubscription({
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: val?.name,
        email: val?.email,
        company_id: currentUser?.company_id,
        trialEndDate:
          companyInfo?.trialEndDate > Math.floor(Date.now() / 1000)
            ? companyInfo?.trialEndDate
            : null,
      });

      var confirmPayment;
      // if (companyInfo?.trialEndDate > Math.floor(Date.now() / 1000)) {
      //   confirmPayment = await stripe?.confirmCardSetup(
      //     response.clientSecret
      //   );
      // } else {
      confirmPayment = await stripe?.confirmCardPayment(response.clientSecret);
      // }

      if (!response?.clientSecret) {
        openNotificationWithIcon("error", "Note", confirmPayment.error.message);
        setPayProgress(false);
      } else {
        openNotificationWithIcon("success", "Note", "Subscription Successful!");
        setPayProgress(false);
        SetSubscriptionModal(false);
        updateCompany({
          id: currentUser.company_id,
          session_id: response.subscriptionId,
          customer_id: response.customerId,
          trialEndDate: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60,
        }).then(() => {
          // setSubscriptionLevel(query.get('subscription'))
          // localStorage.setItem('session_id', response.subscriptionId)
          // setCompanySubscriptionId(response.subscriptionId)
          setCompanyInfo({
            ...companyInfo,
            trialEndDate: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60,
            session_id: response.subscriptionId,
            customer_id: response.customerId,
          });
        });
      }
    } catch (error) {
      console.log(error);
      setPayProgress(false);
    }
  };

  const updateSubscription = async () => {
    setPayProgress(true);
    try {
      // create a new payment method
      const newPaymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: currentUser?.firstname + currentUser?.lastname,
          email: currentUser?.email,
        },
      });

      // confirm the new payment method setup
      // const confirmPaymentSetup = await stripe?.confirmCardSetup(
      //   newPaymentMethod?.paymentMethod?.id,
      //   {
      //     payment_method: {
      //       card: elements?.getElement(CardNumberElement),
      //       billing_details: {
      //         name: currentUser?.firstname + currentUser?.lastname,
      //         email: currentUser?.email,
      //       }
      //     },
      //   }
      // );

      if (!newPaymentMethod?.paymentMethod?.id) {
        openNotificationWithIcon("error", "Note", "PaymentMethod error");
        setPayProgress(false);
      } else {
        // call the backend to update subscription
        const response = await UpdateSubscription({
          // currentPaymentMethodId: currentPaymentMethodId,
          newPaymentMethodId: newPaymentMethod?.paymentMethod?.id,
          subscriptionId: companyInfo?.session_id,
        });

        // confirm the subscription update
        // const confirmSubscription = await stripe?.confirmCardPayment(
        //   response.clientSecret
        // );

        if (!response.clientSecret) {
          openNotificationWithIcon("error", "Note", response?.error.message);
          setPayProgress(false);
        } else {
          openNotificationWithIcon(
            "success",
            "Note",
            "Subscription Updated Successfully!"
          );
          setPayProgress(false);
          SetSubscriptionModal(false);
          setRadioValue(1);
          setChangePaymentMethodFlag(false);
          SeeRenewalDate({ subscriptionId: companyInfo?.session_id }).then(
            (res) => {
              setSeeRenewalDate(res);
            }
          );
          // Update local state or perform additional actions as needed
        }
      }
    } catch (error) {
      console.log(error);
      setPayProgress(false);
    }
  };

  const resubscribe = () => {
    setPayProgress(true);
    // Call your backend to create a new subscription
    Resubscribe({ subscriptionId: companyInfo.session_id })
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Note",
          "Subscription Resubscribed Successfully!"
        );
        // Update local state or perform additional actions as needed
        updateCompany({
          id: currentUser.company_id,
          cancelSubscriptionStatus: false,
        }).then(() => {
          SetSubscriptionModal(false);
          setCompanyInfo({ ...companyInfo, cancelSubscriptionStatus: false });
          setPayProgress(false);
        });
      })
      .catch((error) => {
        console.error("Error resubscribing:", error);
        openNotificationWithIcon(
          "error",
          "Note",
          "Failed to resubscribe the subscription."
        );
        setPayProgress(false);
      });
  };

  const cancelSubscription = () => {
    var con = window.confirm("Are you sure to stop your current subscription?");
    if (con) {
      setPayProgress(true);
      CancelSubscription({ subscriptionId: companyInfo.session_id }).then(
        (res) => {
          // Handle the cancellation information
          if (res.cancel_at_period_end) {
            // Subscription will be canceled at the end of the current billing period
            const cancellationDate = new Date(res.cancel_at * 1000); // Convert timestamp to date
            openNotificationWithIcon(
              "info",
              "Note",
              `Your subscription will be canceled at the end of the current billing period on ${cancellationDate.toDateString()}.`
            );
            setCancelSubscriptionDate(cancellationDate.toDateString());
            updateCompany({
              id: currentUser.company_id,
              cancelSubscriptionStatus: true,
            }).then(() => {
              SetSubscriptionModal(false);
              setCompanyInfo({
                ...companyInfo,
                cancelSubscriptionStatus: true,
              });
              setPayProgress(false);
            });
          } else {
            // Subscription has been immediately canceled
            const canceledDate = new Date(res.canceled_at * 1000); // Convert timestamp to date
            openNotificationWithIcon(
              "success",
              "Note",
              `Your subscription has been canceled on ${canceledDate.toDateString()}.`
            );
            setCancelSubscriptionDate(canceledDate.toDateString());
            updateCompany({
              id: currentUser.company_id,
              session_id: "",
              customer_id: "",
            }).then(() => {
              SetSubscriptionModal(false);
              setCompanyInfo({
                ...companyInfo,
                session_id: "",
                customer_id: "",
              });
              setPayProgress(false);
            });
          }
          // setSubscriptionLevel(query.get('subscription'))
        }
      );
    }
  };

  const handleChangeStripeInput = (event) => {
    // Set error message to be shown when the user inputs incorrect payment data
    if (event.error) {
      setError(event.error.message);
    } else {
      setError("");
    }
  };

  const trialStart = () => {
    TrialStart({ id: companyInfo?.id }).then((res) => {
      if (res) {
        setCompanyInfo({
          ...companyInfo,
          trialEndDate: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60,
        });
      }
      setVisibleModal(false);
    });
  };

  const { TextArea } = Input;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | DOP Test Network</title>
        </MetaTags>
        <ST.CompanyWrapper>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="DOP" breadcrumbItem="Company Admistration" />

            {/* create only company admins */}

            {!currentUser?.company_id && (
              <div>
                <Button className="newCustomer" onClick={() => tog_center()}>
                  Create a Company
                </Button>
              </div>
            )}
            {currentUser?.role_id === 2 && currentUser?.company_id ? (
              <div>
                <Button
                  type="primary"
                  className="newCustomer"
                  size="large"
                  onClick={() => addUser()}
                >
                  Invite Users
                </Button>
                <Form form={form} onFinish={handleSubmit}>
                  <Row>
                    <Col span={8}></Col>
                    <Col
                      xs={24}
                      sm={4}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Form.Item style={{ textAlign: "center" }}>
                        <p>Color logo</p>
                        <ImgCrop rotate aspect={156 / 60} grid>
                          <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={(e) => handleChange(e)}
                          >
                            {fileList.length > 0 ? null : uploadButton}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                      <Form.Item name="logo" style={{ display: "none" }}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={4}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Form.Item style={{ textAlign: "center" }}>
                        <p>Black and White logo</p>
                        <ImgCrop rotate aspect={156 / 60} grid>
                          <Upload
                            listType="picture-card"
                            fileList={file1List}
                            onPreview={handlePreview}
                            onChange={(e) => handleFile1Change(e)}
                          >
                            {file1List.length > 0 ? null : uploadButton}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                      <Form.Item
                        name="black_white_logo"
                        style={{ display: "none" }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "-25px",
                      marginBottom: 0,
                      color: "#ccc",
                    }}
                  >
                    We recommend an image resolution of 900x350
                  </p>
                  {/* <Row>
                    <Col span={10}></Col>
                    <Col span={4} className='subscriptionLevel'>
                      {bankCheck ? (
                        <>
                          <div style={{ marginBottom: 0 }}></div>
                          <div>Bank Connected</div>
                          <div></div>
                          <div  style={{ marginTop: 15, marginBottom: 5, }}>
                            <Button type='primary' disabled={bankCreateButtonStatus} onClick={createBankAccount}>Change bank</Button>
                          </div>
                        </>
                      ) : (
                        <div>
                          <Button type='primary' disabled={bankCreateButtonStatus} onClick={createBankAccount}>Connect bank</Button>
                        </div>
                      )}
                    </Col>
                    <Col span={10}></Col>
                  </Row>
                  <Row>
                    <Col span={8}></Col>
                    <Col span={8} className='subscriptionLevel'>
                      {bankCheck && (
                        <>
                          <nav style={{ display: 'flex', justifyContent: 'center' }}>
                            <Form.Item name='fee_status' label='Credit Card Fees Paid By:'>
                              <Radio.Group>
                                <Space>
                                  <Radio value={true}>Customer</Radio>
                                  <Radio value={false}>Company</Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          </nav>
                        </>
                      )}
                    </Col>
                    <Col span={8}></Col>
                  </Row> */}
                  <Row>
                    <Col span={10}></Col>
                    <Col xs={24} sm={4} className="subscriptionLevel">
                      {companyInfo?.trialEndDate &&
                        companyInfo?.trialEndDate >
                          Math.floor(Date.now() / 1000) && (
                          <h6>
                            <span>Trial End Date:</span>{" "}
                            {moment(
                              new Date(companyInfo?.trialEndDate * 1000)
                            ).format("MM/DD/YYYY")}
                          </h6>
                        )}
                      {!companyInfo?.session_id &&
                        companyInfo?.trialEndDate &&
                        companyInfo?.trialEndDate <
                          Math.floor(Date.now() / 1000) && (
                          <h6>
                            <span>Trial End Date:</span> Lapsed
                          </h6>
                        )}
                      {/* {companyInfo?.trialEndDate < Math.floor(Date.now() / 1000) && (
                        <div onClick={() => {
                          if (!companyInfo?.trialEndDate) {
                            setVisibleModal(true)
                          } else {
                            SetSubscriptionModal(true)
                          }
                        }}>
                          {!companyInfo?.session_id ? <>SUBSCRIPTION</> : <>Subscribed</>}
                        </div>
                      )} */}
                      <div
                        onClick={() => {
                          if (!companyInfo?.trialEndDate) {
                            setVisibleModal(true);
                          } else {
                            SetSubscriptionModal(true);
                          }
                        }}
                      >
                        {!companyInfo?.session_id ||
                        companyInfo?.session_id === "xxxx-xxxx-xxxx" ? (
                          <>Subscribe!</>
                        ) : (
                          <>Subscribed</>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}></Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name="company_name"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Input
                          placeholder="Company Name"
                          style={{
                            width: "100%",
                            height: "38px",
                            marginTop: 15,
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row>
                    <Col span={6}></Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="company_slogan">
                        <TextArea
                          placeholder="This will be displayed on reports and invoices"
                          showCount
                          maxLength={50}
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[18, 0]}>
                    <Col span={6}></Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !phoneCheck.test(value)) {
                                return Promise.reject("Invalid phone number!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Phone Number"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name="website"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !websiteCheck.test(value)) {
                                return Promise.reject("Invalid website url!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Website"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row>
                    <Col span={6}></Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="street">
                        <Input
                          placeholder="Street Address"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[18, 0]}>
                    <Col span={6}></Col>
                    <Col xs={24} sm={4}>
                      <Form.Item name="city">
                        <Input
                          placeholder="City"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                      <Form.Item name="state">
                        {/* <Input placeholder='State' style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} /> */}
                        <Select
                          showSearch
                          className="antdSelect"
                          placeholder="Search to States"
                          options={usStates}
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                      <Form.Item
                        name="zip"
                        rules={[
                          {
                            pattern: /^\d{5}(-\d{4})?$/,
                            message:
                              "Please enter a valid ZIP code (XXXXX or XXXXX-XXXX)",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Zip"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[18, 0]} style={{ marginTop: 20 }}>
                    <Col span={6}></Col>
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div>Initial rate</div>
                        <div>(4) Units tested</div>
                      </header>
                      <Form.Item
                        name="initial_rate"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !numberCheck.test(value)) {
                                return Promise.reject("Invalid number!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div>Additional</div>
                        <div>Per unit rate</div>
                      </header>
                      <Form.Item
                        name="additional_rate"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !numberCheck.test(value)) {
                                return Promise.reject("Invalid number!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[18, 0]} style={{ marginBottom: 20 }}>
                    <Col span={6}></Col>
                    {/* <Col xs={24} sm={4}>
                      <header className="labelHeader">
                        <div>DOP Certificate #</div>
                        <div>Starting Value</div>
                      </header>
                      <Form.Item
                        name="dop_certificate_start"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !numberCheck.test(value)) {
                                return Promise.reject("Invalid number!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div>Company Invoice Prefix#</div>
                        <div>Prefix Value</div>
                      </header>
                      <Form.Item name="invoice_start_prefix">
                        <Input
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div>Company Invoice #</div>
                        <div>Starting Value</div>
                      </header>
                      <Form.Item
                        name="invoice_start"
                        rules={[
                          {
                            validator(_, value) {
                              if (value && !numberCheck.test(value)) {
                                return Promise.reject("Invalid number!");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[18, 0]} style={{ marginBottom: 20 }}>
                    <Col span={6}></Col>
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div></div>
                        <div>Send copies of invoices to:</div>
                      </header>
                      <Form.Item name="copy_invoices_user">
                        <Select
                          showSearch
                          className="antdSelect"
                          defaultValue="N/A"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return (
                              option.title
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {companyUsers &&
                            companyUsers.map((res) => (
                              <Option
                                key={[res.email]}
                                title={`${res.firstname} ${res.lastname}`}
                                value={res.id}
                                disabled={res.leave}
                              >
                                {res.firstname} {res.lastname}
                              </Option>
                            ))}
                          <Option value={null}>N/A</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <header className="labelHeader">
                        <div></div>
                        <div>Send copies of reports to:</div>
                      </header>
                      <Form.Item name="copy_reports_user">
                        <Select
                          showSearch
                          className="antdSelect"
                          defaultValue="N/A"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return (
                              option.title
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {companyUsers &&
                            companyUsers.map((res) => (
                              <Option
                                key={[res.email]}
                                title={`${res.firstname} ${res.lastname}`}
                                value={res.id}
                                disabled={res.leave}
                              >
                                {res.firstname} {res.lastname}
                              </Option>
                            ))}
                          <Option value={null}>N/A</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row>
                    <Col span={6}></Col>
                    <Col xs={24} sm={12}>
                      {/* {companyAdmins && companyAdmins.map(res => (
                        <Form.Item>
                          <Input disabled style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} value={res.email} />
                        </Form.Item>
                      ))} */}
                      <Form.Item>
                        <Select
                          className="antdSelect"
                          value={discountSelect}
                          onChange={discountChange}
                        >
                          <Select.Option value="enabled">
                            Discounts: Enabled
                          </Select.Option>
                          <Select.Option value="disabled">
                            Discounts: Disabled
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        Discount Teirs
                      </div>
                      <Form.Item name="discount">
                        <Checkbox.Group
                          options={discountOptions}
                          disabled={discountStatus}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>

                  <div className="row">
                    <div className="col-md-5">
                      <Button type="primary" htmlType="submit">
                        Save Settings
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            ) : (
              <Alert
                message="Forbidden"
                description="Only administrators have access to this page, if you require changes to the company page, please contact your administrator."
                type="error"
                closable
              />
            )}
          </Container>
          <ComfirmModal
            content="Are you sure you would like to create a company?"
            modal_center={modal_center}
            setmodal_center={setmodal_center}
            tog_center={tog_center}
            type={"create-company"}
            data={{
              create_user_id: currentUser?.sub,
              create_user_email: currentUser?.email,
            }}
          />
          <SuccessModal
            title="Updated Successfully!"
            content="Your company information upadated"
            modal_center={modal_center1}
            setmodal_center={setmodal_center1}
            tog_center={tog_center1}
          />
          <ST.StyleModal
            title="Users"
            visible={isModalVisible}
            onCancel={handleCancel}
          >
            <p>
              {/* <Form form={form} name="control-hooks" onFinish={onFinish}> */}
              <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                initialValues={{
                  message: "Join the rest of your team at DOP Test Network!",
                }}
              >
                <Form.Item
                  name="email"
                  label="User Email"
                  rules={[
                    { required: true, message: "This field is required" },
                    {
                      validator(_, value) {
                        if (value && !value.match(validRegex)) {
                          return Promise.reject("Invalid email address!");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                      height: "38px",
                      borderRadius: "0.25rem",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <TextArea
                    rows={4}
                    style={{ width: "100%", borderRadius: "0.25rem" }}
                  />
                </Form.Item>
                <Form.Item
                  name="role_id"
                  label="Role"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Select style={{ width: 200 }}>
                    {roleList.map(
                      (res) =>
                        res.id !== 1 && (
                          <Option key={res.id} value={res.id}>
                            {res.name}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  wrapperCol={{ offset: 5, span: 19 }}
                  style={{ textAlign: "left" }}
                >
                  <Button
                    htmlType="button"
                    onClick={() => setIsModalVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={sendButton}
                    type="primary"
                    htmlType="submit"
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </p>
          </ST.StyleModal>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handlePreviewCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
          {/* <Modal visible={subscriptionModal} width='900px' footer={null} onCancel={() => SetSubscriptionModal(false)}>
            <div  style={{ display: 'flex', justifyContent: 'center', gap: '40px', padding: '30px' }}>
              <ST.SubscriptionCard className={subscriptionLevel === 'basic' ? 'selected' : ''}>
                <div className='basic'>
                  <header>
                    Basic
                  </header>
                  <nav>
                    $2.99
                  </nav>
                  <section>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>

                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CloseOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CloseOutlined />
                      Sample Text Here
                    </div>
                  </section>
                  {!subscriptionLevel && (
                    <footer onClick={() => subscriptionSelect('basic')}>
                      Buy Now
                    </footer>
                  )}
                  {subscriptionLevel && subscriptionLevel !== 'basic' && (
                    <footer onClick={() => subscriptionSelect('basic')}>
                      Downgrade
                    </footer>
                  )}
                </div>
              </ST.SubscriptionCard>
              <ST.SubscriptionCard className={subscriptionLevel === 'standard' ? 'selected' : ''}>
                <div className='standard'>
                  <header>
                    Standard
                  </header>
                  <nav>
                    $5.99
                  </nav>
                  <section>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CheckOutlined />
                      Sample Text Here
                    </div>
                    <div>
                      <CloseOutlined />
                      Sample Text Here
                    </div>
                  </section>
                  {!subscriptionLevel && (
                    <footer onClick={() => subscriptionSelect('standard')}>
                      Buy Now
                    </footer>
                  )}
                  {subscriptionLevel === 'premium' && (
                    <footer onClick={() => subscriptionSelect('standard')}>
                      Downgrade
                    </footer>
                  )}
                  {subscriptionLevel === 'basic' && (
                    <footer onClick={() => subscriptionSelect('standard')}>
                      Upgrade
                    </footer>
                  )}
                </div>
              </ST.SubscriptionCard>
              <ST.SubscriptionCard className={subscriptionLevel === 'premium' ? 'selected' : ''}>
              <div className='premium'>
                <header>
                  Premium
                </header>
                <nav>
                  $9.99
                </nav>
                <section>
                  <div>
                    <CheckOutlined />
                    Sample Text Here
                  </div>
                  <div>
                    <CheckOutlined />
                    Sample Text Here
                  </div>
                  <div>
                    <CheckOutlined />
                    Sample Text Here
                  </div>
                  <div>
                    <CheckOutlined />
                    Sample Text Here
                  </div>
                  <div>
                    <CheckOutlined />
                    Sample Text Here
                  </div>
                </section>
                {!subscriptionLevel && (
                  <footer onClick={() => subscriptionSelect('premium')}>
                    Buy Now
                  </footer>
                )}
                {subscriptionLevel && subscriptionLevel !== 'premium' && (
                  <footer onClick={() => subscriptionSelect('premium')}>
                    Upgrade
                  </footer>
                )}
              </div>
            </ST.SubscriptionCard>
            </div>
            <div className="grid gap-4 m-auto">
              <Input
                placeholder="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <br />
              <Input
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <CardElement />
              <Button onClick={createSSubscription} disabled={!stripe}>
                Pay Now
              </Button>
            </div>
            <ST.SubscriptionModal>
              <nav>
                $99.99
              </nav>
              {!companySubscriptionId ? (
                <Row>
                  <Col span={2}></Col>
                  <Col span={20}>
                    <Form
                      form={formStripe}
                      onFinish={createSubscription}
                    >
                      <Form.Item name="name" rules={[{ required: true, message: 'This field is required' }]}>
                        <Input placeholder='Name' style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: 'This field is required' },
                          {
                            validator(_, value) {
                              if (value && !value.match(validRegex)) {
                                return Promise.reject('Invalid email address!')
                              }
                              return Promise.resolve()
                            }
                          }
                        ]}
                      >
                        <Input placeholder='Email' style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} />
                      </Form.Item>
                      {error && <span className="error">{error}</span>}
                      <div className='cardInfoRow'>
                        <label>Card number</label>
                        <CardNumberElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div className='cardInfoRow'>
                        <label>Expiration date</label>
                        <CardExpiryElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div className='cardInfoRow'>
                        <label>CVC</label>
                        <CardCvcElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button type="primary" htmlType="submit" disabled={payProgress}>
                          Buy Now
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Button danger type='primary' disabled={payProgress} onClick={() => cancelSubscription()}>Stop Subscription</Button>
                </div>
              )}
            </ST.SubscriptionModal>
          </Modal> */}

          <ST.SubscriptModal
            className="subscriptionModal"
            visible={subscriptionModal}
            width="900px"
            footer={null}
            onCancel={() => SetSubscriptionModal(!subscriptionModal)}
          >
            {!companyInfo?.session_id ||
            companyInfo?.session_id === "xxxx-xxxx-xxxx" ? (
              <>
                <Row>
                  <Col span={2}></Col>
                  <Col span={20}>
                    <h3 style={{ textAlign: "center" }}>Subscribe!</h3>
                    <p />
                    <Form
                      form={formStripe}
                      onFinish={createSubscription}
                      initialValues={{
                        name: `${currentUser?.firstname} ${currentUser?.lastname}`,
                        email: currentUser?.email,
                      }}
                    >
                      <Form.Item name="name">
                        <Input
                          placeholder="Name"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="email">
                        <Input
                          placeholder="Email"
                          style={{
                            width: "100%",
                            height: "38px",
                            borderRadius: "0.25rem",
                          }}
                          disabled
                        />
                      </Form.Item>
                      <Row style={{ marginBottom: 15 }}>
                        <Col span={6}>Price: ${companyInfo?.price} / month</Col>
                        <Col span={12}>
                          {companyInfo?.trialEndDate &&
                            companyInfo?.trialEndDate >
                              Math.floor(Date.now() / 1000) && (
                              <h6>
                                <span>Trial End Date:</span>{" "}
                                {moment(
                                  new Date(companyInfo?.trialEndDate * 1000)
                                ).format("MM/DD/YYYY")}
                              </h6>
                            )}
                          {!companyInfo?.session_id &&
                            companyInfo?.trialEndDate &&
                            companyInfo?.trialEndDate <
                              Math.floor(Date.now() / 1000) && (
                              <h6>
                                <span>Trial End Date:</span> Lapsed
                              </h6>
                            )}
                        </Col>
                      </Row>
                      {error && <span className="error">{error}</span>}
                      <div className="cardInfoRow">
                        <label>Card number</label>
                        <CardNumberElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div className="cardInfoRow">
                        <label>Expiration date</label>
                        <CardExpiryElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div className="cardInfoRow">
                        <label>CVC</label>
                        <CardCvcElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleChangeStripeInput}
                        />
                      </div>
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={payProgress}
                        >
                          Pay Now
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </>
            ) : (
              <>
                {/* {companyInfo?.cancelSubscriptionStatus ? (
                <>
                  Your subscription has been canceled on {cancelSubscriptionDate}
                </>  
              ) : ( */}
                <>
                  <div>
                    {!companyInfo?.cancelSubscriptionStatus ? (
                      <div style={{ marginBottom: 10 }}>
                        Renewal date:{" "}
                        {seeRenewalDate?.nextPaymentDate &&
                          moment(seeRenewalDate.nextPaymentDate).format(
                            "MM/DD/YYYY"
                          )}
                        <span style={{ marginLeft: 30 }}>
                          Last 4 digits: {seeRenewalDate?.lastFourDigits}
                        </span>
                      </div>
                    ) : (
                      <div style={{ marginBottom: 10 }}>
                        Your subscription has been canceled on{" "}
                        {cancelSubscriptionDate}
                        <span style={{ marginLeft: 30 }}>
                          Last 4 digits: {seeRenewalDate?.lastFourDigits}
                        </span>
                      </div>
                    )}
                    <Radio.Group
                      value={radioValue}
                      onChange={(e) => {
                        setChangePaymentMethodFlag(
                          e.target.value == 1 ? false : true
                        );
                        setRadioValue(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value={1}>Stop this current subscription.</Radio>
                        <Radio value={2}>
                          {!companyInfo?.cancelSubscriptionStatus
                            ? "Change the payment method."
                            : "Resubscribe"}
                        </Radio>
                      </Space>
                    </Radio.Group>

                    {changePaymentMethodFlag &&
                      !companyInfo?.cancelSubscriptionStatus && (
                        <div style={{ margin: "10px 25px 10px" }}>
                          <div>
                            {error && <span className="error">{error}</span>}
                          </div>
                          <div className="cardInfoRow">
                            <label>Card number</label>
                            <CardNumberElement
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={handleChangeStripeInput}
                            />
                          </div>
                          <div className="cardInfoRow">
                            <label>Expiration date</label>
                            <CardExpiryElement
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={handleChangeStripeInput}
                            />
                          </div>
                          <div className="cardInfoRow">
                            <label>CVC</label>
                            <CardCvcElement
                              options={CARD_ELEMENT_OPTIONS}
                              onChange={handleChangeStripeInput}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {!changePaymentMethodFlag ? (
                      !companyInfo?.cancelSubscriptionStatus ? (
                        <Button
                          danger
                          type="primary"
                          disabled={payProgress}
                          onClick={() => cancelSubscription()}
                        >
                          Stop Subscription
                        </Button>
                      ) : (
                        <Button danger type="primary" disabled={true}>
                          Pendding
                        </Button>
                      )
                    ) : !companyInfo?.cancelSubscriptionStatus ? (
                      <Button
                        type="primary"
                        disabled={payProgress}
                        onClick={() => updateSubscription()}
                      >
                        Change
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        disabled={payProgress}
                        onClick={() => resubscribe()}
                      >
                        Resubscribe
                      </Button>
                    )}
                  </div>
                </>
                {/* )} */}
              </>
            )}
          </ST.SubscriptModal>
          <Modal
            visible={visibleModal}
            width="900px"
            footer={null}
            onCancel={() => setVisibleModal(!visibleModal)}
          >
            <h3 style={{ textAlign: "center" }}>
              Dopsitenetwork provides free trial for 30 days.
            </h3>
            <Button type="primary" onClick={() => trialStart()}>
              Start free
            </Button>
          </Modal>
        </ST.CompanyWrapper>
      </div>
    </React.Fragment>
  );
};

export default Company;

/* eslint-disable */
import styled from 'styled-components'

export const Wrapper = styled.div`
  .containerFix {
    padding: 0 80px;
    background: #D9EEE1!important;
  }
  header {
    .containerFix .row {
      padding: 15px 0;
    }
    .linkSection {
      display: flex;
      justify-content: end;
      align-items: center;
      a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7F7F7F;
        margin-left: 40px;
      }
      a: last-child {
        background: linear-gradient(90deg, #D14124 -0.05%, #B02509 99.95%);
        box-shadow: 0px 4px 25px rgba(255, 0, 0, 0.2);
        border-radius: 4px;
        padding: 10px 40px;
        color: white;
      }
    }
  }
  section {
    .containerFix {
      margin-top: -387px;
    }
    .polygon {
      background: linear-gradient(90deg,#D14124 -0.05%,#B02509 99.95%);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 50%);
      height: 446px;
    }
    nav {
      position: relative;
      text-align: center;
      color: white;
      div:first-child {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 50px;
      }
      div:last-child {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
      }
      .dividerLeft {
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 25px;
        width: 16%;
        left: 16%;
      }
      .dividerRight {
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 25px;
        width: 16%;
        right: 16%;
      }
    }
  }
  .widget {
    margin-bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding: 20px;
    &>div:last-child {
      text-align: justify;
      font-size: 18px;
      ::first-letter {
        margin-left: 30px;
      }
    }
    &>h2 {
      margin-top: -60px;
      line-height: 50px;
      ::first-letter {
        margin-left: 60px;
      }
    }
    color: #7F7F7F;
    .title {
      background: #D14124;
      border-radius: 50%;
      color: white;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding: 10px;
      display: inline-block;
      width: 40.12px;
      height: 40.12px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .rightSection {
    width: 70%;
    background: #FFFFFF;
    border-radius: 67px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 25px;
    .col-md-4 {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 30px;
      img {
        width: 80%;
        hieght: auto;
      }
    }
    .content {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #7F7F7F;
      margin-bottom: 50px;
      text-align: justify;
      ::first-letter {
        margin-left: 30px;
      }
    }
    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #404656;
      margin-bottom: 20px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .leftSection {
    margin-top: 70px;
    .col-md-6:last-child {
      margin-top: 90px;
    }
  }

  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .logoSection {
      margin-bottom: 20px;
      margin-top: 20px;
      img {
        width: 25%;
      }
    }
  
    .linkSection {
      flex-direction: column;
    }
  
    .linkSection a {
      margin: 0;
      margin-bottom: 10px;
    }
    .widget, .rightSection {
      width: 100%;
      border-radius: 10px;
    }
    .containerFix {
      padding: 0 30px;
    }
    .rightSection img {
      width: 40% !important;
    }
    .dividerLeft, .dividerRight {
      display: none;
    }
    section .polygon {
      clip-path: polygon(0 0,100% 0%,100% 100%,0% 80%);
    }
    .widget>h1 {
      font-size: 25px;
    }
  }



  .header {
    background-image: url(https://www.w3schools.com/spaces/workspace.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 0px center;
    position: relative;
    font-family: 'Source Sans Pro', sans-serif!important;
    text-align: center!important;

    .w3-content {
      max-width: 980px;
      margin-left: auto;
      margin-right: auto;

      .top-section {
        padding-top:150px; color: #ffffff;padding-left:25px;padding-right:25px;
        padding-bottom: 130px;
        .hero-title {
          color: #ffffff;font-size: 58px;font-weight:700;
        }
        .details-description {
          color: #ffffff;
          font-family: "Segoe UI",Arial,sans-serif;
          font-weight: 400;
          margin: 10px 0;
          span {
            color: #FFC0C7;
          }
        }
      }
    }

    svg {
      width: 100%; margin-bottom: -9px;
    }
  }

  .w3-center {
    text-align: center!important;
    padding-top:50px; padding-bottom:50px;
    font-family: sans-serif;

    .w3-center {
      text-align: center!important;
      max-width: 980px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 8px;
      letter-spacing: 0.5px
    }

  }
  .w3-center:last-child {
    padding-bottom: 50px;
  }
  .w3-padding-32 {
    padding-top: 32px!important;
    padding-bottom: 32px!important;
  }
  .w3-right {
    float: right!important;
  }
  .w3-left-align {
      text-align: left !important;
  }

  .ws-light-green {
    background-color: #D9EEE1!important;
    color: black!important;
  }
  .ws-green {
    background-color: #04AA6D!important;
    h3, h6 {
      color: white!important;
    }
  }


  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .scroll-to-top div {
    background-color: #007bff;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    font-size: 24px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    svg {
      margin-top: -10px;
    }
  }
  
  .scroll-to-top div:hover {
    background-color: #0056b3;
  }

  @media screen and (max-width: 768px) {
    .logoSection {
      text-align: left;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    .mobileLink {
      display: flex;
      align-items: center;
      justify-content: right;
      a {
        margin-left: 10px;
      }
      a:last-child {
        color: #d14124;
      }
    }
    .flowEdge {
      display: none;
    }
    // .cIMna>.w3-center {
    //   padding-bottom: 0 !important;
    // }
  }
`
/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { getProject } from '../../data/project.js'
import { getInvoiceById } from "../../data/invoice";
import { Checkbox, Button, Select, Form } from "antd";
import { DownOutlined } from "@ant-design/icons";
// import { PDFExport, savePDF } from  '@progress/kendo-react-pdf'
import moment from "moment";
import {
  Container,
  // Table,
  Input,
  FormGroup,
} from "reactstrap";
import * as ST from "./styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import { SendInvoice, getInvoiceNum } from "../../data/invoice.js";
import SuccessModal from "../../components/Modal/successModal";
// import getInvoicesId from '../../data/swr/invoices/invoiceProjectId.js'
import Pdf from "./pdf.js";
import { getUsersByCompanyId } from "../../data/user";

const validRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const { Option } = Select;
const Projects = () => {
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [editData, setEditData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [companyDiscount, setCompanyDiscount] = useState();
  const [discountStatus, setDiscountStatus] = useState(true);
  const [discountValue, setDiscountValue] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const { id } = useParams();
  // const pdfExportComponent = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState();
  const [otherContactStatus, setOtherContactStatus] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [form] = Form.useForm();

  const { currentUser } = useSelector((state) => ({
    currentUser: state.Login.user,
  }));
  const nowDate = new Date();

  const { result, mutate } = getMachines({ project_id: editData?.project_id });
  // const { result: invoiceResult } = getInvoicesId({ project_id: editData?.project_id })

  useEffect(() => {
    getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
        setCompanyDiscount(res[0]?.discount?.split(","));
        form.setFieldsValue({ ...res[0], technician_email: currentUser.email });
      }
    });

    getInvoiceById({ id: id }).then((res) => {
      var params = {
        customer_email: res[0].customer_email,
        customer_name: res[0].customer_name,
        customer_phone: res[0].customer_phone,
        contact_name: res[0].contact_name,
        contacts: JSON.parse(res[0].contacts) || [],
        project_name: res[0].project_name,
        customer_id: res[0].customer_id,
        ses_project_id: res[0].ses_project_id,
        reference_id: res[0].reference_id,
        project_city: res[0].project_city,
        project_state: res[0].project_state,
        project_street: res[0].project_street,
        project_zip: res[0].project_zip,
        project_id: res[0].project_id,
        invoice_date: moment(res[0].invoice_date).format(
          "YYYY-MM-DD  HH:mm:ss"
        ),
        city: res[0].city,
        state: res[0].state,
        street: res[0].street,
        zip: res[0].zip,
        discount: res[0].Discount,
        terms: res[0].terms,
        extra_test_num: result?.length < 4 ? 0 : result?.length - 4,
        invoice_id: res[0].invoice_id,
        ID: res[0].ID,
        sender_user_id: currentUser?.sub,
        initial_rate: res[0].initial_rate,
        additional_rate: res[0].additional_rate,
        copy_invoices_user: res[0].copy_invoices_user,
        job_number: res[0].job_number,
      };
      form.setFieldsValue({
        ...res[0],
        contact_email_report: res[0].contact_email,
      });
      setEditData(params);

      if (
        res[0].Discount &&
        res[0].Discount !== "0" &&
        res[0].Discount !== "N/A"
      ) {
        setDiscountStatus(false);
        setDiscountValue(res[0].Discount);
      } else {
        setDiscountStatus(true);
      }
    });
  }, [id, result]);
  useEffect(() => {
    getInvoiceNum({ id: currentUser?.company_id }).then((res) => {
      setInvoiceId(Number(Number(res[0].num) + 1));
    });
  }, []);

  useEffect(() => {
    if (currentUser?.company_id) {
      getUsersByCompanyId({ company_id: currentUser?.company_id }).then(
        (res) => {
          setCompanyUsers(
            res?.filter((e) => e?.firstname !== "" && e?.lastname !== "")
          );
        }
      );
    } else {
      setCompanyUsers([]);
    }
  }, []);

  const tog_center = () => {
    setmodal_center(!modal_center);
  };
  const goMachine = () => {
    history.push(`/invoices`);
  };
  const onChangeCheckbox = (val) => {
    if (val.target.checked) {
      setDiscountStatus(false);
    } else {
      setDiscountStatus(true);
    }
  };
  const onChangeDiscount = (val) => {
    setDiscountValue(val.target.value);
  };
  const sendInvoice = val => {
    setInvoiceButtonLoading(true);
    const params = {
      editData: editData,
      companyInfo: companyInfo,
      discount: !discountStatus && discountValue ? discountValue : 0,
      subtotal:
        editData?.initial_rate +
        editData?.extra_test_num * editData?.additional_rate,
      resend: true,
      currentUser: { ...currentUser, email: val.technician_email },
      ...val,
    };
    SendInvoice(params).then((res) => {
      if (res) {
        setmodal_center(true);
        setInvoiceButtonLoading(false);
      }
    });
  };

  const handleExportWithComponent = async (event) => {
    try {
      const pdfContent =
        document.getElementsByClassName("pdf-page")[0].innerHTML;
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/report/makePdfInvoice",
        {
          method: "POST",
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: pdfContent }),
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "example.pdf"; // Set the filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // pdfExportComponent.current.save();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project/Invoice | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.GeneratedWrapper>
            <Container fluid>
              <Breadcrumbs title="DOP" breadcrumbItem="Project/Invoice" />
              <div className="back d-print-none" onClick={() => goMachine()}>
                <i className="dripicons-arrow-thin-left"></i>
                <span>Back</span>
              </div>
              {/* <div style={{ textAlign: "center" }}>
                <Select
                  defaultValue={layoutSelection}
                  onChange={(e) => setLayoutSelection(e)}
                  style={{ width: 200 }}
                >
                  <Option value="size-a4">A4</Option>
                  <Option value="size-letter">Letter</Option>
                  <Option value="size-executive">Execution</Option>
                </Select>
              </div> */}
              <div className="table-responsive">
                <div className={`pdf-page ${layoutSelection}`}>
                  <Pdf
                    companyInfo={companyInfo}
                    editData={editData}
                    result={result}
                    nowDate={nowDate}
                    discountValue={discountValue}
                    discountStatus={discountStatus}
                  />
                </div>
              </div>
              <footer className="d-print-none">
                {!discountStatus && (
                  <div style={{ textAlign: "center" }}>
                    <Checkbox
                      onChange={onChangeCheckbox}
                      checked={!discountStatus}
                      disabled
                    >
                      Enable Discount
                    </Checkbox>
                    <FormGroup>
                      <Input
                        type="select"
                        value={discountValue}
                        disabled
                        onChange={onChangeDiscount}
                      >
                        <option value="">N/A</option>
                        {companyDiscount &&
                          companyDiscount.map((res) => (
                            <>
                              {editData?.discount && (
                                <>
                                  {Number(res) <= Number(editData.discount) ? (
                                    <option key={res} value={res}>
                                      {res}%
                                    </option>
                                  ) : (
                                    <option
                                      style={{ background: "gainsboro" }}
                                      key={res}
                                      value={res}
                                      disabled="disabled"
                                    >
                                      {res}%
                                    </option>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                      </Input>
                      <DownOutlined />
                    </FormGroup>
                  </div>
                )}
                <div style={{ textAlign: "center" }}>
                  <Button
                    // disabled={invoiceButtonLoading}
                    className="newCustomer"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Resend
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    className="newCustomer"
                    onClick={() => handleExportWithComponent()}
                  >
                    Download PDF
                  </Button>
                </div>
              </footer>
            </Container>
            <ST.StyleModal
              title="Edit"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={sendInvoice}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  // initialValues={{ email: editUserData?.email }}
                >
                  <h3>Invoice</h3>
                  <hr />
                  <Form.Item
                    name="technician_email"
                    label="Technician:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="copy_invoices_user"
                    label="Send copies of invoices to:"
                  >
                    <Select
                      showSearch
                      className="antdSelect"
                      defaultValue="N/A"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option.title
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {companyUsers &&
                        companyUsers.map((res) => (
                          <Option
                            key={[res.email]}
                            title={`${res.firstname} ${res.lastname}`}
                            value={res.id}
                            disabled={res.leave}
                          >
                            {res.firstname} {res.lastname}
                          </Option>
                        ))}
                      <Option value={null}>N/A</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="customer_email"
                    label="Customer:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() =>
                        setOtherContactStatus(!otherContactStatus)
                      }
                      checked={otherContactStatus}
                    >
                      Other customer contacts:
                    </Checkbox>
                  </Form.Item>
                  {otherContactStatus && (
                    <Form.Item name="contact_emails" label="Contacts:">
                      <Select
                        mode="multiple"
                        size="middle"
                        placeholder="Please select"
                        style={{
                          width: "100%",
                        }}
                      >
                        {editData?.contacts &&
                          editData.contacts.map((res, index) => (
                            <Option key={index} value={res.contact_email}>
                              {res.contact_email +
                                " (" +
                                res.contact_firstname +
                                "  " +
                                res.contact_lastname +
                                ")"}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() => setIsOthers(!isOthers)}
                      checked={isOthers}
                    >
                      To other:
                    </Checkbox>
                  </Form.Item>
                  {isOthers && (
                    <Form.Item
                      name="other_email"
                      label="Other:"
                      rules={[
                        {
                          validator(_, value) {
                            if (value && !value.match(validRegex)) {
                              return Promise.reject("Invalid email address!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          height: "38px",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Button
                      htmlType="button"
                      onClick={() => setIsModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={invoiceButtonLoading}
                    >
                      Resend
                    </Button>
                  </Form.Item>
                </Form>
              </p>
            </ST.StyleModal>
            <SuccessModal
              title="Successfully!"
              content="Your sent invoice to customer"
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
            />
          </ST.GeneratedWrapper>
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Link as LinkA } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import { Spin } from 'antd';

//import images
import logo from '../../assets/images/logo-sm-full.png'
import flowIcon from "../../assets/images/home/flowIcon.png";
import * as ST from './styles'
import ScrollToTopButton from './ScrollToTopButton';

const PageMaintenance = props => {
  const [formLoaded, setFormLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setFormLoaded(true);
  }, []);

useEffect(() => {
    // Check screen width on component mount and on window resize
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768); // Assuming 768px is the breakpoint for mobile devices
    };

    checkScreenWidth(); // Check screen width on component mount

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  
  return (
    <ST.Wrapper>
      <MetaTags>
        <title>Home | DOP Test Network</title>
      </MetaTags>
   
      <div className='header'>
          <header>
            <div className='containerFix'>
              <div className='row'>
                <div className='col-md-4 col-6 logoSection'>
                  <Link to="/dashboard" className="">
                    <img src={logo} alt='' />
                  </Link>
                </div>
                {!isMobile ? (
                  <div className='col-md-8 col-6 linkSection'>
                    
                    <div>
                      <Link to="top" onClick={scrollToTop}>
                        <span>Home</span>
                      </Link>
                      <Link to="contact" duration={500}>
                        <span>Contact</span>
                      </Link>
                      <LinkA to="/login" className="">
                        <span>Login</span>
                      </LinkA>
                    </div>
                  </div>
                ) : (
                  <div className='col-md-8 col-6 mobileLink'>
                    
                    <div>
                      <Link to="top" onClick={scrollToTop}>
                        <span>Home</span>
                      </Link>
                      <Link to="contact" duration={500}>
                        <span>Contact</span>
                      </Link>
                      <LinkA to="/login" className="">
                        <span>Login</span>
                      </LinkA>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </header>
          <div class="w3-content">
            <div class="top-section">
              <h1 class="hero-title">
                DOP Test Network
              </h1>
              <h4 class="details-description">Your Complete Solution for <span>Testing, Certificates, Reports & Invoicing.</span></h4>
            </div>
          </div>
          <svg viewBox="0 0 5950 394" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="wavepath0" fill-rule="evenodd" clip-rule="evenodd" d="M0 293.923V393.09H330.556H1983.33H3966.67L5950 393.091L5950 203.02C5288.89 244.34 4627.78 194.756 3966.67 128.645C3878.52 119.83 3790.37 110.722 3702.22 101.613C3129.26 42.4069 2556.3 -16.7992 1983.33 4.6869C1322.22 29.4786 661.111 161.701 330.556 227.812L0 293.923Z" fill="#D9EEE1"></path>
          </svg>
      </div>

      <div class="w3-center ws-light-green">
        <div class="w3-center w3-content w3-row-padding">
            <div className='row'>
              <div className='col-md-5'></div>
              <div className='col-md-7'>
                <h3 class="rightside-text-padding w3-left-align"><b>Optimize Your Operations</b></h3>
                <h6 class="rightside-text-padding w3-left-align">Achieve seamless efficiency with our custom workflow solution. Empower staff to set up customers and projects from the office, cutting down costly duplicate manual entries on-site.</h6>
              </div>
            </div>
        </div>                        
      </div>

      <div class="w3-center ws-green">
        <div class="w3-center w3-content w3-row-padding">
            <div class="w3-center w3-padding-32 w3-row w3-container">
              <div className='row'>
                <div className='col-md-7'>
                  <h3 class="rightside-text-padding w3-left-align"><b>Streamline Label Generation</b></h3>
                  <h6 class="rightside-text-padding w3-left-align">Effortlessly generate on-site labels with our instant system. No more delays - create printable labels in the field after machine testing. Simplify your workflow and ensure compliance and efficiency.</h6>
                </div>
                <div className='col-md-5'></div>
              </div>
            </div>
        </div>
      </div>

      <div class="w3-center ws-light-green">
        <div class="w3-center w3-content w3-row-padding">
            <div className='row'>
              <div className='col-md-5'></div>
              <div className='col-md-7'>
                <h3 class="rightside-text-padding w3-left-align"><b>Optimize Cashflow</b></h3>
                <h6 class="rightside-text-padding w3-left-align">Achieve seamless job closure with our automated system. Finish a job and instantly generate and send invoices and reports for swift turnaround. Send them directly to clients for quick payment processing.</h6>
              </div>
            </div>
        </div>                        
      </div>

      <svg className='flowEdge' style={{ width: "100%", marginTop: "-45px" }} viewBox="0 0 5950 194" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M330.556 118.216L0 152.511V193.831H330.556H1983.33H3966.67H5950V0.869141C5804.56 6.95963 5659.11 13.7101 5513.67 20.4605C4998 44.3939 4482.33 68.3273 3966.67 62.8483C3674.12 60.4714 3381.57 47.8189 3089.02 35.1665C2720.46 19.2265 2351.9 3.2864 1983.33 7.89345C1322.22 16.1573 661.111 82.2684 330.556 118.216Z" fill="#282A35"></path>
      </svg>
      {/* Contact section */}
        <div name="contact" style={{ background: "#282A35", marginTop: "-15px" }}>
              <div style={{ paddingTop: "50px" }}>
                {formLoaded ? (
                  <iframe
                    src="https://api.leadconnectorhq.com/widget/form/kBMQMOJKzu0gwpDPQRYh"
                    style={{ width: '100%', height: '100%', border: 'none', borderRadius: '4px' }}
                    id="inline-kBMQMOJKzu0gwpDPQRYh"
                    data-layout="{'id':'INLINE'}"
                    data-trigger-type="alwaysShow"
                    data-trigger-value=""
                    data-activation-type="alwaysActivated"
                    data-activation-value=""
                    data-deactivation-type="neverDeactivate"
                    data-deactivation-value=""
                    data-form-name="DOP Test Network Lead"
                    data-height="683"
                    data-layout-iframe-id="inline-kBMQMOJKzu0gwpDPQRYh"
                    data-form-id="kBMQMOJKzu0gwpDPQRYh"
                    title="DOP Test Network Lead"
                  >
                  </iframe>
                ) : (
                  <Spin tip="Loading..." />
                )}
              </div>
        </div>
        <ScrollToTopButton />
    </ST.Wrapper>
  )
}

export default PageMaintenance;
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from "react-redux"
import { CloseOutlined } from '@ant-design/icons'

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TrComponent from '../../components/Projects/TrMachines'
import { ReactComponent as PlusIcon } from '../../assets/images/adminIcons/plusIcon.svg'
import { getProject, reOpen } from '../../data/project.js'
import { getByProject } from '../../data/invoice'
import { getRepotByProject } from '../../data/report'
import { Checkbox, Button as ButtonAntd } from 'antd'
import {
  Container,
  Table,
  Button
} from "reactstrap";
import * as ST from './styles'
import getMachines from '../../data/swr/machines/machines.js'
import { openNotificationWithIcon } from '../../components/Modal/notification'
import MachineIcon from '../../assets/images/machine-icon.png'

const Projects = () => {
  const history = useHistory()
  const [editData, setEditData] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isReOpenModal, setIsReOpenModal] = useState(false)
  const [invoiceId, setInvoiceId] = useState()
  const [reportId, setReportId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [closeCheckbox, setCloseCheckbox] = useState(false)
  const [requireStatus, setRequireStatus] = useState(false)

  const { id } = useParams();
  const { currentUser, currentCompany } = useSelector(state => ({
    currentUser: state.Login.user,
    currentCompany: state.Login.company
  }))
  const { result, mutate } = getMachines({ project_id: id })

  useEffect(() => {
    getByProject({ id: id }).then(res => {
      setInvoiceId(res?.id)
    })
    getRepotByProject({ id: id }).then(res => {
      setReportId(res[0]?.id)
    })
  }, [id])
  useEffect(() => {
    getProject({id: id}).then(res => {
      var params = {
        customer_email: res[0].customer_email,
        customer_name: res[0].customer_name,
        project_name: res[0].project_name,
        customer_id: res[0].customer_id,
        ses_project_id: res[0].ses_project_id,
        reference_id: res[0].reference_id,
        project_city: res[0].project_city,
        project_state: res[0].project_state,
        project_street: res[0].project_street,
        project_zip: res[0].project_zip,
        project_status: res[0].status,
        city: res[0].city,
        state: res[0].state,
        street: res[0].street,
        zip: res[0].zip
      }
      setEditData(params)
    })
  }, [id])
  const goAddMachine = () => {
    if (editData?.project_status) {
      openNotificationWithIcon('error', 'Note', "Can't add. This project was closed.")
    } else {
      history.push(`/projects/addMachine/${id}`)
    }
  }
  const goMachine = () => {
    history.push('/projects')
  }
  const printInvoice = () => {
    history.push(`/reports/view/${reportId}`)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const sendInvoice = () => {
    if (currentCompany?.trialEndDate < Math.floor(Date.now() / 1000) && !currentCompany?.session_id) {
      openNotificationWithIcon('error', 'Note', "You must have an active subscription or trial to use this feature.")
      return
    }
    if (editData?.project_status) {
      openNotificationWithIcon('error', 'Note', "Can't close. This project was closed.")
    } else if(result?.length === 0) {
      openNotificationWithIcon('error', 'Note', "Must to have one more machine at least.")
    } else {
      history.push(`/invoices/${id}`)
    }
  }
  const printLabel = () => {
    history.push(`/projects/printDops/${id}`)
  }

  const reOpenFun = () => {
    setIsLoading(true)
    reOpen({ id: id }).then(res => {
      setIsLoading(false)
      openNotificationWithIcon('success', 'Note', "This project has been re-opened.")
      setIsReOpenModal(false)
      setEditData({ ...editData, project_status: false })
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project/machines | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.MachinesWrapper>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="DOP" breadcrumbItem="Project/Machines" />
              <div className='back d-print-none' onClick={() => goMachine()}>
                <i className='dripicons-arrow-thin-left'></i>
                <span>Back</span>
              </div>
              <div className='row panel'>
                <div className='col-md-3'>
                  <header>Customer</header>
                  <div>{editData?.customer_name}</div>
                  <div>{editData?.customer_email}</div>
                  <div>{editData?.street}</div>
                  <div>{editData?.city}, {editData?.state}, {editData?.zip}</div>
                </div>
                {/* <div className='col-md-3'>
                  <header>Customer Contacts</header>
                  {editData?.contacts && JSON.parse(editData?.contacts).map(res => (
                    <div>{res.contact_firstname} {res.contact_lastname} ({res.contact_email})</div>
                  ))}
                </div> */}
                <div className='col-md-3'>
                  <header>Project</header>
                  <div>Project Name: <span>{editData?.project_name}</span></div>
                  <div>Project Number: <span>{editData?.ses_project_id}</span></div>
                  <div>Client Reference: <span>{editData?.reference_id}</span></div>
                </div>
                <div className='col-md-3'>
                  <div style={{ marginTop: 30 }}><span>{editData?.project_street}</span></div>
                  <div><span>{editData?.project_city}, {editData?.project_state}</span></div>
                  <div><span>{editData?.project_zip}</span></div>
                </div>
                <div className='col-md-3 d-print-none'>
                  <div onClick={() => goAddMachine()}>
                    <img src={MachineIcon} width={40} alt='' />
                    <div>Add Machine</div>
                  </div>
                  <div className='plusIcon'>
                    <PlusIcon />
                  </div>
                  <div>Machines: {result ? result.length : 0}</div>
                </div>
              </div>
              <div className="table-responsive">
                <header>Job Equipment Certificate Status</header>
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Certificate #</th>
                      <th>Equipment Type</th>
                      <th>Make</th>
                      <th>Model</th>
                      <th>Serial #</th>
                      <th>Pass/Fail</th>
                      <th className='d-print-none'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {result && result.map((res, index) => (
                      <TrComponent key={index} tableData={res} mutate={mutate}  Role={currentUser?.role_id} status={editData?.project_status}  />
                    ))}
                  </tbody>
                </Table>
              </div>
              {currentUser?.role_id !== 9 && (
                <footer className='d-print-none'>
                  <Button onClick={() => printLabel()}>Print Certificates</Button>
                  {editData?.project_status ? (
                    <>
                      <Button onClick={() => printInvoice()}>View Report</Button>
                      <Button onClick={() => history.push(`/invoices/view/${invoiceId}`)}>View invoice</Button>
                      <Button onClick={() => setIsReOpenModal(true)}>Re-Open</Button>
                    </>
                  ) : (
                    <>
                    <Button disabled={!editData?.project_status} onClick={() => printInvoice()}>View Report</Button>
                      <Button onClick={() => setIsModalVisible(true)}>Close Project</Button>
                    </>
                  )}
                </footer>
              )}
            </Container>
            <ST.StyleModal title="Users" visible={isModalVisible}  onCancel={handleCancel}>
              <header>
                Are you sure you want to close the project?
                <CloseOutlined onClick={() => setIsModalVisible(false)} />
              </header>
              <nav>You will no longer be able to make any changes to this project once you close it.</nav>
              <aside>
                <Checkbox onChange={
                  e => {
                    setCloseCheckbox(!closeCheckbox)
                    setRequireStatus(!e.target.checked)
                  }
                }>
                  All machines have been tested and labeled for this project
                </Checkbox>
              </aside>
              {requireStatus && (
                <div style={{ marginTop: '-20px', color: 'red' }}>This field is required.</div>
              )}
              <div>
                <ButtonAntd
                  type="primary"
                  danger
                  onClick={() => {
                    closeCheckbox ? sendInvoice() : setRequireStatus(true)
                  }}
                >
                  Close Project
                </ButtonAntd>
              </div>
            </ST.StyleModal>
            <ST.StyleModal title="Users" visible={isReOpenModal}  onCancel={() => setIsReOpenModal(false)}>
              <header><CloseOutlined onClick={() => setIsReOpenModal(false)} /></header>
              <h3>This will remove the invoice and report associated with this project.</h3>
              <h4>Do you want to continue?</h4>
              <div>
                <ButtonAntd
                  type="primary"
                  danger
                  onClick={() => reOpenFun()}
                  disabled={isLoading}
                >
                  Continue
                </ButtonAntd>
              </div>
            </ST.StyleModal>
          </ST.MachinesWrapper>
        )}
      </div>
    </React.Fragment>
  )
}

export default Projects;
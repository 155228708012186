import { get, post } from "../helpers/api_helper"

export const SendInvoice = data => post('/invoice/sendInvoice', data)
export const SendReport = data => post('/invoice/SendReport', data)
export const getInvoiceNum = data => get(`/invoice/getInvoiceNum/${data.id}`)
export const getJobNumber = data => get(`/invoice/getJobNumber/${data.ses_project_id}`)

export const getInvoiceById = data => get(`/invoice/getInvoiceById/${data.id}`)

export const getInvoice = data => get(`/invoice/get/${data.id}`)
export const paymentSecret = data => post(`/invoice/paymentSecret`, data)
export const payed = data => post(`/invoice/payed`, data)
export const getInvoiceByCompany = data => post(`/invoice/getInvoiceByCompany/${data.id}`, data)

export const getAccount = () => get(`/invoice/getAccount`)
export const transterToCompany = data => post(`/invoice/pay`, data)

export const CreateSubscription = data => post('/invoice/createSubscription', data)
export const UpdateSubscription = data => post('/invoice/updateSubscription', data)
export const AttachPaymentMethod = data => post('/invoice/attachPaymentMethod', data)
export const SeeRenewalDate = data => post('/invoice/seeRenewalDate', data)
export const Resubscribe = data => post('/invoice/resubscribe', data)


export const createPortalSession = data => post('/invoice/createPortalSession', data)
export const CancelSubscription = data => post('/invoice/cancelSubscription', data)
export const SeeLastNextPaymentDate = data => post('/invoice/SeeLastNextPaymentDate', data)
export const TrialStart = data => post('/invoice/trialStart', data)

export const getByProject = data => get(`/invoice/getByProject/${data.id}`)
/* eslint-disable */
import styled from 'styled-components'

export const Wrapper = styled.div`
  & {
    .ant-form-item-explain-error {
      margin-top: -7px;
    }
    .antdSelect .ant-select-selector {
      height: 38px;
      border-radius: 0.25rem;
      margin-bottom: 15px;
    }
    header {
      font-size: 15px;
      cursor: pointer;
      display: inline-block;
      span {    
        line-height: 15px;
        margin-left: 4px;
      }
      i, span {
        line-height: 15px;
      }
    }
    .ant-form-item {
      margin-bottom: 25px !important;
    }
    .form-group {
      margin-bottom: 15px;
      position: relative;
      span.inputTitle {
        position: absolute;
        top: -10px;
        left: 15px;
        padding: 0 8px;
        background: white;
      }
      img, .anticon.anticon-down {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 12px;
        height: auto;
      }
    }
    .profileImg img {
      border-radius: 100%;
    }
    #signature {
      display: flex;
      height: 100%;
    }
    #signature ~ img {
      top: calc(50% - 5px) !important;
    }
    .col-md-5 button {
      width: 100%;
      background: linear-gradient(90deg, #D14124 -0.05%, #B02509 99.95%);
      box-shadow: 0px 4px 25px rgba(255, 0, 0, 0.2);
      border-radius: 4px;
      color: white;
    }
    .row.header {
      font-family: 'Montserrat';
      font-weight: 700;
      font-style: normal;
      font-size: 22px;
      text-align: center;
      margin-bottom: 30px;
    }
    .row:last-child {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
`
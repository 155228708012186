/* eslint-disable */
// import React, { useState, useEffect } from "react";
const currentYear = new Date().getFullYear();
const lastTwoDigits = currentYear.toString().slice(-2);
const Pdf = ({
  companyInfo,
  editData,
  result,
  reportData,
  index,
  pageLength,
}) => {
  // const [base64Image, setBase64Image] = useState("");
  // const [base64ImageSignature, setBase64ImageSignature] = useState("");

  // useEffect(() => {
  //   if (companyInfo?.logo && !companyInfo.logo.startsWith("data:image/")) {
  //     fetch(`${process.env.REACT_APP_URL}/company-logo/${companyInfo.logo}`)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         // Check if the size of the image is greater than 50 KB
  //         if (blob.size > 50 * 1024) {
  //           // 50 KB in bytes
  //           // If image size is greater than 50 KB, reduce quality
  //           reduceImageQuality(blob, "logo");
  //         } else {
  //           // If image size is not greater than 50 KB, set the image directly
  //           const reader = new FileReader();
  //           reader.readAsDataURL(blob);
  //           reader.onloadend = () => {
  //             setBase64Image(reader.result);
  //           };
  //         }
  //       });
  //   }
  // }, [companyInfo]);

  // useEffect(() => {
  //   if (
  //     reportData?.signature_logo &&
  //     !reportData?.signature_logo.startsWith("data:image/")
  //   ) {
  //     fetch(
  //       `${process.env.REACT_APP_URL}/company-logo/${reportData?.signature_logo}`
  //     )
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         // Check if the size of the image is greater than 50 KB
  //         if (blob.size > 50 * 1024) {
  //           // 50 KB in bytes
  //           // If image size is greater than 50 KB, reduce quality
  //           reduceImageQuality(blob, "signature");
  //         } else {
  //           // If image size is not greater than 50 KB, set the image directly
  //           const reader = new FileReader();
  //           reader.readAsDataURL(blob);
  //           reader.onloadend = () => {
  //             setBase64ImageSignature(reader.result);
  //           };
  //         }
  //       });
  //   }
  // }, [reportData]);

  // const reduceImageQuality = (blob, type) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onload = () => {
  //     const tempImage = new Image();
  //     tempImage.src = reader.result;
  //     tempImage.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       const ctx = canvas.getContext("2d");
  //       const scaleFactor = (70 * 1024) / blob.size; // Calculate scale factor to achieve 50 KB size
  //       canvas.width = tempImage.width * scaleFactor;
  //       canvas.height = tempImage.height * scaleFactor;
  //       ctx.drawImage(tempImage, 0, 0, canvas.width, canvas.height);
  //       const dataURL = canvas.toDataURL("image/jpeg"); // Adjust quality here
  //       if (type === "logo") {
  //         // setBase64Image(dataURL);
  //       } else {
  //         setBase64ImageSignature(dataURL);
  //       }
  //     };
  //   };
  // };

  // useEffect(() => {
  //   if (
  //     reportData?.signature_logo &&
  //     !reportData?.signature_logo.startsWith("data:image/")
  //   ) {
  //     fetch(
  //       `${process.env.REACT_APP_URL}/company-logo/${reportData?.signature_logo}`
  //     )
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(blob);
  //         reader.onloadend = () => {
  //           setBase64ImageSignature(reader.result);
  //         };
  //       });
  //   }
  // }, [reportData]);

  return (
    <div
      style={{ fontFamily: "ArialFont", fontSize: 10, padding: ".3in .3in" }}
    >
      <div
        id="header-content"
        style={{ paddingBottom: 1, borderBottom: "3px solid", marginTop: index === 0 ? '-10px' : '0' }}
      >
        <div style={{ float: "left", width: "50%", boxSizing: "border-box" }}>
          <div style={{ minHeight: 55 }}>
            <img
              src={`${process.env.REACT_APP_URL}/company-logo/${companyInfo?.logo}`}
              height="55"
              alt=""
            />
          </div>
        </div>
        <div
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            fontSize: 7,
            whiteSpace: "nowrap",
            fontFamily: "TimesNewRomanFont",
            minHeight: 55,
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              fontFamily: "TimesNewRomanFont",
            }}
          >
            {companyInfo?.company_slogan}
          </span>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>

      <div style={{ textAlign: "right", marginTop: 5 }}>
        Page{" "}
        <span style={{ borderBottom: "1px solid #000000" }}>
          {index + 1} of {pageLength}
        </span>
      </div>

      <div id="pdf-content">
        <div
          style={{
            textAlign: "center",
            // fontWeight: "600",
            fontSize: 12,
            fontFamily: "ArialBoldFont",
          }}
        >
          <b>DOP AEROSOL TEST CERTIFICATION REPORT</b>
        </div>
        {/* {index === 0 && ( */}
          <>
            <div style={{ margin: "10px 0" }}>
              <div
                style={{
                  float: "left",
                  width: "33.33%",
                  boxSizing: "border-box",
                }}
              >
                Report Date:{" "}
                <span style={{ borderBottom: "1px solid #000000" }}>
                  {editData?.report_date?.split(" ")[0]}
                </span>
              </div>
              <div
                style={{
                  float: "left",
                  width: "33.33%",
                  boxSizing: "border-box",
                }}
              >
                Test Date:{" "}
                <span style={{ borderBottom: "1px solid #000000" }}>
                  {editData?.tested_at?.split(" ")[0]}
                </span>
              </div>
              <div
                style={{
                  float: "left",
                  width: "33.33%",
                  boxSizing: "border-box",
                }}
              >
                SES Job No.:{" "}
                <span style={{ borderBottom: "1px solid #000000" }}>
                  {/* {companyInfo?.invoice_start_prefix
                    ? companyInfo.invoice_start_prefix +
                      "-" +
                      editData?.ses_project_id
                    : editData?.ses_project_id}
                  -{reportData?.job_number + 1} */}
                  {editData?.ses_project_id}-{editData?.job_number}
                </span>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>

            <div style={{ margin: "10px 0 0 0" }}>
              <div
                style={{
                  float: "left",
                  width: "50%",
                  boxSizing: "border-box",
                  paddingRight: 3,
                }}
              >
                <section style={{ border: "1px solid", paddingBottom: 5 }}>
                  <div
                    style={{
                      padding: "3px 5px",
                      borderBottom: "1px solid",
                      marginBottom: 3,
                      fontWeight: 600,
                      fontFamily: 'ArialBoldFont',
                    }}
                  >
                    Client:
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    {editData?.customer_name}
                  </div>
                  <div style={{ padding: "0 5px" }}>{editData?.street}</div>
                  <div style={{ padding: "0 5px" }}>
                    {editData?.city}, {editData?.state} {editData?.zip}
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    Phone: {editData?.customer_phone}
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    Email: <a href="#">{editData?.customer_email}</a>
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    Attention: {editData?.contacts?.[0]?.contact_firstname}{" "}
                    {editData?.contacts?.[0]?.contact_lastname}
                  </div>
                </section>
              </div>
              <div
                style={{
                  float: "left",
                  width: "50%",
                  boxSizing: "border-box",
                  paddingLeft: 3,
                }}
              >
                <section style={{ border: "1px solid", paddingBottom: 5 }}>
                  <div
                    style={{
                      padding: "3px 5px",
                      borderBottom: "1px solid",
                      marginBottom: 3,
                      fontWeight: 600,
                      fontFamily: 'ArialBoldFont',
                    }}
                  >
                    Project Information:
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    {editData?.project_name}
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    {editData?.project_street}
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    {editData?.project_city}, {editData?.project_state}{" "}
                    {editData?.project_zip}
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    Client Ref No.: {editData?.reference_id}
                  </div>
                  <div style={{ padding: "0 5px", color: "white" }}>
                    Email: {editData?.customer_email}
                  </div>
                  <div style={{ padding: "0 5px", color: "white" }}>
                    Attention: {editData?.contacts?.[0]?.contact_firstname}{" "}
                    {editData?.contacts?.[0]?.contact_lastname}
                  </div>
                </section>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
          </>
        {/* )} */}

        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            textAlign: "center",
            fontFamily: "ArialFont",
            fontSize: 10,
            marginTop: 10,
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "10%",
                  lineHeight: "12px",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                DOP Test Certificate No.
              </th>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "25%",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                Equipment Type
              </th>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "25%",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                Make
              </th>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "15%",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                Model
              </th>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "17%",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                Serial #
              </th>
              <th
                style={{
                  border: "1px solid #000000",
                  padding: 3,
                  background: "rgb(204, 204, 204)",
                  fontWeight: 600,
                  width: "8%",
                  fontFamily: 'ArialBoldFont',
                }}
              >
                Pass / Fail
              </th>
            </tr>
          </thead>
          <tbody>
            {result &&
              result.slice(index * 14, index * 14 + 14).map((res, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                  {lastTwoDigits}-{res?.certificate_id}
                  </td>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                    {res?.equipment_type}
                  </td>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                    {res?.make}
                  </td>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                    {res?.model}
                  </td>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                    {res?.serial_id}
                  </td>
                  <td style={{ border: "1px solid #000000", padding: 5 }}>
                    {res?.equipment_test ? <>Pass</> : <>Fail</>}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          position: "absolute",
          width: "calc(100% - 0.6in)",
          bottom: "0.3in",
        }}
        className="footer-content"
      >
        {index + 1 === pageLength && (
          <div style={{ margin: 0 }}>
            <div style={{ float: "left", width: "50%", boxSizing: "border-box" }}>
              <div style={{ margin: 0 }}>
                <div
                  style={{
                    float: "left",
                    width: "40%",
                    boxSizing: "border-box",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  Inspector Name:
                </div>
                <div
                  style={{ float: "left", width: "60%", boxSizing: "border-box" }}
                >
                  <div style={{ borderBottom: "1px solid" }}>
                    {reportData?.firstname} {reportData?.lastname}
                  </div>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
            <div style={{ float: "left", width: "50%", boxSizing: "border-box" }}>
              <div style={{ margin: 0 }}>
                <div
                  style={{
                    float: "left",
                    width: "40%",
                    boxSizing: "border-box",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  Signature:
                </div>
                <div
                  style={{
                    float: "left",
                    width: "60%",
                    boxSizing: "border-box",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid",
                      position: "absolute",
                      bottom: "-15px",
                    }}
                  >
                    <img
                      src={
                        reportData?.signature_level
                          ? reportData?.signature
                          : `${process.env.REACT_APP_URL}/company-logo/${reportData?.signature_logo}`
                      }
                      alt=""
                      style={{ height: "35px", width: "100px" }}
                    />
                  </div>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        )}

        <div
          style={{
            textAlign: "center",
            padding: 7,
            marginBottom: 3,
            fontWeight: "600",
            borderBottom: "3px solid",
            fontFamily: "ArialBoldFont",
            fontSize: "9px",
          }}
        >
          Testing Performed in Accordance with ANSI/ASME N510-2007
        </div>

        <div
          style={{
            margin: 0,
            fontSize: 8,
            fontFamily: "TimesNewRomanFont",
          }}
        >
          <div style={{ float: "left", width: "50%", boxSizing: "border-box" }}>
            <span style={{ fontFamily: "TimesNewRomanFont" }}>
              {/* {companyInfo?.company_name}: <span className="space5" /> */}
              {companyInfo?.street}, <span className="space5" />
              {companyInfo?.city}, <span className="space5" />
              {companyInfo?.state} <span className="space5" />
              {companyInfo?.zip}
            </span>
          </div>
          <div
            style={{
              float: "left",
              width: "50%",
              boxSizing: "border-box",
              textAlign: "right",
            }}
          >
            <span style={{ fontFamily: "TimesNewRomanFont" }}>
              Phone: {companyInfo?.phone}
            </span>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Pdf;

/* eslint-disable */
import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const TrComponent = ({ tableData, Role, companyInfo }) => {
  const history = useHistory();
  const goView = val => {
    history.push(`/reports/view/${val}`)
  }

  const goProjectView = val => {
    history.push(`/projects/machines/${val}`)
  }

  const goInvoiceView = val => {
    history.push(`/invoices/view/${val}`)
  }

  const goCustomerView = val => {
    history.push(`/customers/detail/${val}`)
  }

  return (
    <tr>
      <td>
        {Role !== 9 && (
          <a href='#' onClick={() => goView(tableData?.id)}>
            {/* {companyInfo?.invoice_start_prefix ? (
              <>{companyInfo?.invoice_start_prefix}-{tableData?.invoice_id}</>
            ) : (
              <>{tableData?.invoice_id}</>
            )} */}
            {tableData?.ses_project_id}-{tableData?.jobNumber}
          </a>
        )}
      </td>
      <td>
        {Role !== 9 && (
          <a href='#' onClick={() => goInvoiceView(tableData?.invoiceId)}>
            {/* {companyInfo?.invoice_start_prefix ? (
              <>{companyInfo?.invoice_start_prefix}-{tableData?.invoice_id}</>
            ) : (
              <>{tableData?.invoice_id}</>
            )} */}
            {tableData?.ses_project_id}-{tableData?.jobNumber}
          </a>
        )}
      </td>
      <td>
        {Role !== 9 && (
          <a href='#' onClick={() => goProjectView(tableData?.project_id)}>{tableData?.ses_project_id}</a>
        )}
      </td>
      <td>{moment(tableData?.report_date).format('MM/DD/YYYY HH:mm:ss')}</td>
      <td>{tableData?.pass_num}</td>
      <td>{tableData?.fail_num}</td>
      
      <td>
        {Role !== 9 && (
          <a href='#' onClick={() => goCustomerView(tableData?.customer_id)}>{tableData?.customer_name}</a>
        )}
      </td>

      <td>{tableData?.reference_id}</td>
      {tableData?.status === 0 ? (
        <td className='greenBg'>Open</td>
      ): <td>Closed</td>}
      <td>
        {Role !== 9 && (
          <a href='#' onClick={() => goView(tableData?.id)}>View</a>
        )}
      </td>
    </tr>
  )
}

export default TrComponent

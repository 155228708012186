/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux"
import { Table, Select, Button, Spin, Tag, Form, Input, Radio, Space } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { companyDelete, getCompany, companyApproveState, BackupDatabase } from '../../data/comany'
import { openNotificationWithIcon } from '../../components/Modal/notification'

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container
} from "reactstrap";
import * as ST from './styles'
import { inviteCompany } from '../../data/notificate_invite'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input
const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const Invoices = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [deleteCompanyID, setDeleteCompanyID] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [sendButton, setSendButton] = useState(false)
  const [form] = Form.useForm()

  const { currentUser } = useSelector(state => ({
    currentUser: state.Login.user
  }))
  
  useEffect(() => {
    getCompany().then(res => {
      setCompanyData(res)
    })
  }, [isModalVisible])
  // useEffect(() => {
  //   backupDatabase()
  // }, [])
  const subscriptionStatus = e => {
    if (!e?.trialEndDate) {
      return (<Tag icon={<MinusCircleOutlined />} color="default">
        N/A
      </Tag>)
    }
    if (e?.trialEndDate && e?.trialEndDate > Math.floor(Date.now() / 1000)) {
      return (
        <Tag icon={<ClockCircleOutlined />} color="warning">
          Trial
        </Tag>)
    }
    if (e?.trialEndDate && e?.trialEndDate < Math.floor(Date.now() / 1000) && !e?.session_id) {
      return (<Tag icon={<CloseCircleOutlined />} color="error">
        Trial Lapsed
      </Tag>)
    }
    if (e?.session_id) {
      return (<Tag icon={<CheckCircleOutlined />} color="success">
          Active
      </Tag>)
    }
  }
  const columns = [
    {
      title: 'New Company Applicant',
      key: 'ID',
      render: data => {
        return <>{data.firstname} {data.lastname}({data.email})</>
      }
    },
    {
      title: 'Company Name',
      key: 'company_name',
      render: data=> {
        return <Link to={`/company-profile/${data?.company_id}`}>
                {data?.company_name}
              </Link>
      }
    },
    {
      title: 'Status',
      key: 'ID',
      render: data => {
        return (
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            defaultValue={data.status}
            onChange={val => companyOnApproveState(val, data)}
          >
            <Option value={1}>Approve</Option>
            <Option value={0}>Reject</Option>
          </Select>
        )
      }
    },
    {
      title: 'CreatedAt',
      dataIndex: 'created_at',
      key: 'created_at',
      render: data => {
        return <>{moment(data).format("MM/DD/YYYY")}</>
      }
    },
    {
      title: 'Subscription',
      key: 'trialEndDate',
      render: data => {
        return <div className='decriptionLabel'>{subscriptionStatus(data)}</div>
      }
    },
    {
      title: '',
      key: 'ID',
      render: data => {
        return (
          <a href='#' onClick={() => companyOnDelete(data)}>Delete</a>
        )
      }
    },
  ]
  const companyOnApproveState = (val, data) => {
    let status = false
    if (val === 1) status = true
    companyApproveState({ id: data.ID, status: status, site_admin_email: currentUser.email, site_admin_name: `${currentUser.firstname} ${currentUser.lastname}`, name: `${data.firstname} ${data.lastname}`, email: `${data.email}` }).then(res => {
      openNotificationWithIcon('success', 'Note', 'Changed successfully')
    }).catch(err => {
      openNotificationWithIcon('error', 'Note', 'Failed')
    })
  }
  const companyOnDelete = data => {
    setIsModalVisible(true)
    setDeleteCompanyID(data)
  }
  const deleteCompany = () => {
    companyDelete({ id: deleteCompanyID.ID, site_admin_email: currentUser.email, site_admin_name: `${currentUser.firstname} ${currentUser.lastname}`, name: `${deleteCompanyID.firstname} ${deleteCompanyID.lastname}`, email: `${deleteCompanyID.email}`}).then(res => {
      openNotificationWithIcon('success', 'Note', 'Deleted successfully')
      setIsModalVisible(false)
    }).catch(err => {
      openNotificationWithIcon('error', 'Note', 'Failed')
    })
  }

  const backupDatabase = () => {
    setLoading(true)
    BackupDatabase().then(res => {
      setLoading(false)
      // const fileData = JSON.stringify(userInfo);
      const blob = new Blob([res], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = "database.sql";
      link.href = url;
      link.click();
    })
  }

  const onFinish = (values) => {
    setSendButton(true)
    if (values.trial === 1) {
      values.trial = true
    } else {
      values.trial = false
    }
    console.log(values)
    inviteCompany(values).then((res) => {
      if (res?.message === 'success') {
        openNotificationWithIcon('success', 'Note', 'Sent your invitation successfully')
        setIsInviteModalVisible(false)
      } else {
        openNotificationWithIcon('error', 'Note', res?.message)
        setIsInviteModalVisible(false)
      }
      setSendButton(false)
    }).catch(err => {
      openNotificationWithIcon('error', 'Note', 'Failed')
      setSendButton(false)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | DOP Test Network</title>
        </MetaTags>
        <ST.CompanyWrapper>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="DOP" breadcrumbItem="Company Admistration" />
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin tip="Backup database..." />
              </div>
            ) : (
              <>
                <div className='row'>
                  <div className='col-xs-12 col-md-6'>
                    <Button type="primary"  className='newCustomer' size='large' onClick={() => backupDatabase()}>Backup database</Button>
                  </div>
                  <div className='col-xs-12 col-md-6' style={{ textAlign: 'right' }}>
                    <Button type="primary" className='newCustomer' size='large' onClick={() => setIsInviteModalVisible(true)}>Invite Company</Button>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table dataSource={companyData} columns={columns} />
                </div>
              </>
            )}
          </Container>
        </ST.CompanyWrapper>
      </div>
      <ST.StyleModal title="Users" visible={isModalVisible}  onCancel={() => setIsModalVisible(false)}>
        <div style={{ textAlign: 'center', marginBottom: 15 }}>
          <p>Are you sure delete this company?</p>
          <Button htmlType="button" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>
          <Button type="danger" htmlType="submit" onClick={() => deleteCompany()} style={{ marginLeft: 15 }}>
            Delete
          </Button>
        </div>
      </ST.StyleModal>

      
      <ST.StyleModal title="Invite Company" visible={isInviteModalVisible}  onCancel={() => setIsInviteModalVisible(false)}>
        <p>
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ message: 'You’ve been invited to join DOP Test Network! Finally, Dispersed Oil Particulate Test Certifications Made Easy!' }}
          >
            <Form.Item name="email" label="Company Email"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  validator(_, value) {
                    if (value && !value.match(validRegex)) {
                      return Promise.reject('Invalid email address!')
                    }
                    return Promise.resolve()
                  }
                }
              ]}
            >
              <Input style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} />
            </Form.Item>
            <Form.Item name="company_name" label="Company name"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input style={{ width: '100%', height: '38px', borderRadius: '0.25rem' }} />
            </Form.Item>
            <Form.Item name="message" label="Message"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <TextArea
                rows={4}
                style={{ width: '100%', borderRadius: '0.25rem' }}
              />
            </Form.Item>
            <Form.Item name="trial" label="Subscription"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={1}>Trial for 30 days</Radio>
                  <Radio value={2}>Active</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 19 }} style={{ textAlign: 'left' }}>
              <Button htmlType="button" onClick={() => setIsInviteModalVisible(false)}>
                Cancel
              </Button>
              <Button disabled={sendButton} type="primary" htmlType="submit" >
                Send
              </Button>
            </Form.Item>
          </Form>
        </p>
      </ST.StyleModal>
    </React.Fragment>
  )
}

export default Invoices
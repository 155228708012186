/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

const Pdf = ({
  companyInfo,
  editData,
  nowDate,
  discountValue,
  discountStatus,
}) => {
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    if (companyInfo?.logo && !companyInfo.logo.startsWith("data:image/")) {
      fetch(`${process.env.REACT_APP_URL}/company-logo/${companyInfo.logo}`)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
        });
    }
  }, [companyInfo]);

  console.log('xxxxxxxxxxxxxxxxxxxxxxxxxx')
  return (
    <div
      style={{ fontFamily: "ArialFont", fontSize: 10, padding: ".5in .3in" }}
    >
      <div style={{ paddingBottom: 1, borderBottom: "3px solid" }}>
        <div style={{ float: "left", width: "50%", boxSizing: "border-box" }}>
          <div style={{ minHeight: 55 }}>
            <img
              src={`${process.env.REACT_APP_URL}/company-logo/${companyInfo?.logo}`}
              height="55"
              alt=""
            />
            {/* {base64Image && (
              <img src={base64Image} height='70' alt='' />
            )} */}
          </div>
        </div>
        <div
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            fontSize: 7,
            whiteSpace: "nowrap",
            fontFamily: "TimesNewRomanFont",
            minHeight: 55,
            position: "relative",
          }}
        >
          <span style={{ position: "absolute", right: 0, bottom: 0 }}>
            {companyInfo?.company_slogan}
          </span>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>

      <div style={{ textAlign: "right", marginTop: 5 }}>
        Page <span style={{ borderBottom: "1px solid #000000" }}>1 of 1</span>
      </div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: 12,
          fontFamily: "ArialBoldFont",
        }}
      >
        DOP AEROSOL TEST CERTIFICATION INVOICE
      </div>

      <div style={{ margin: "10px 0" }}>
        <div
          style={{ float: "left", width: "49.99%", boxSizing: "border-box" }}
        >
          Invoice Date:{" "}
          <span style={{ borderBottom: "1px solid #000000" }}>
            {nowDate
              ? moment(nowDate).format("MM/DD/YYYY")
              : editData?.invoice_date?.split(" ")[0]}
          </span>
        </div>
        <div
          style={{ float: "left", width: "49.99%", boxSizing: "border-box" }}
        >
          SES Invoice No.:{" "}
          {/* <span style={{ borderBottom: "1px solid #000000" }}>
            {companyInfo?.invoice_start_prefix
              ? companyInfo.invoice_start_prefix + "-" + editData?.invoice_id
              : editData?.invoice_id}
          </span> */}
          <span style={{ borderBottom: "1px solid #000000" }}>
            {editData?.ses_project_id}-{editData?.job_number}
          </span>
        </div>
        {/* <div
          style={{ float: "left", width: "33.33%", boxSizing: "border-box" }}
        >
          SES Job No.:{" "}
          <span style={{ borderBottom: "1px solid #000000" }}>
            {companyInfo?.invoice_start_prefix &&
              companyInfo?.invoice_start_prefix + "-"}
            {editData?.ses_project_id}-{editData?.job_number}
          </span>
        </div> */}
        <div style={{ clear: "both" }}></div>
      </div>

      <div style={{ margin: "10px 0" }}>
        <div
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            paddingRight: 3,
          }}
        >
          <section style={{ border: "1px solid", paddingBottom: 5 }}>
            <div
              style={{
                padding: "3px 5px",
                borderBottom: "1px solid",
                marginBottom: 3,
                fontWeight: 600,
              }}
            >
              Client:
            </div>
            <div style={{ padding: "0 5px" }}>{editData?.customer_name}</div>
            <div style={{ padding: "0 5px" }}>{editData?.street}</div>
            <div style={{ padding: "0 5px" }}>
              {editData?.city}, {editData?.state} {editData?.zip}
            </div>
            <div style={{ padding: "0 5px" }}>
              Phone: {editData?.customer_phone}
            </div>
            <div style={{ padding: "0 5px" }}>
              Email: <a href="#">{editData?.customer_email}</a>
            </div>
            <div style={{ padding: "0 5px" }}>
              Attention: {editData?.contacts?.[0]?.contact_firstname}{" "}
              {editData?.contacts?.[0]?.contact_lastname}
            </div>
          </section>
        </div>
        <div
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            paddingLeft: 3,
          }}
        >
          <section style={{ border: "1px solid", paddingBottom: 5 }}>
            <div
              style={{
                padding: "3px 5px",
                borderBottom: "1px solid",
                marginBottom: 3,
                fontWeight: 600,
              }}
            >
              Project Information:
            </div>
            <div style={{ padding: "0 5px" }}>{editData?.project_name}</div>
            <div style={{ padding: "0 5px" }}>{editData?.project_street}</div>
            <div style={{ padding: "0 5px" }}>
              {editData?.project_city}, {editData?.project_state}{" "}
              {editData?.project_zip}
            </div>
            <div style={{ padding: "0 5px" }}>
              Client Reference No.: {editData?.reference_id}
            </div>
            <div style={{ padding: "0 5px", color: "white" }}>
              Email: {editData?.customer_email}
            </div>
            <div style={{ padding: "0 5px", color: "white" }}>
              Attention: {editData?.contacts?.[0]?.contact_firstname}{" "}
              {editData?.contacts?.[0]?.contact_lastname}
            </div>
          </section>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>

      <div className="table-responsive">
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            textAlign: "center",
            fontFamily: "ArialFont",
            fontSize: 10,
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
                width="60%"
              >
                DESCRIPTION
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                QTY
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                RATE
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                AMOUNT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: 5 }}>
                EQUIPMENT TESTING-4 units
              </td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}>1</td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}>
                {editData?.initial_rate}
              </td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}>
                {editData?.initial_rate?.toFixed(2)}
              </td>
            </tr>
            {editData?.extra_test_num !== 0 && (
              <tr>
                <td style={{ border: "1px solid #ddd", padding: 5 }}>
                  Extra units tested
                </td>
                <td style={{ border: "1px solid #ddd", padding: 5 }}>
                  {editData?.extra_test_num}
                </td>
                <td style={{ border: "1px solid #ddd", padding: 5 }}>
                  {editData?.additional_rate}
                </td>
                <td style={{ border: "1px solid #ddd", padding: 5 }}>
                  {(
                    editData?.extra_test_num * editData?.additional_rate
                  ).toFixed(2)}
                </td>
              </tr>
            )}
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  color: "transparent",
                }}
              >
                '
              </td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  color: "transparent",
                }}
              >
                '
              </td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  color: "transparent",
                }}
              >
                '
              </td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
              <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
            </tr>
            <tr className="grayTr">
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(217, 217, 217)",
                }}
              ></td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(217, 217, 217)",
                }}
                colspan="2"
              >
                SUBTOTAL
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(217, 217, 217)",
                }}
              >
                {(
                  editData?.initial_rate +
                  editData?.extra_test_num * editData?.additional_rate
                ).toFixed(2)}
              </td>
            </tr>
            {!discountStatus && (
              <tr className="grayTr">
                <td style={{ border: "1px solid #ddd", padding: 5 }}></td>
                <td
                  style={{ border: "1px solid #ddd", padding: 5 }}
                  colspan="2"
                >
                  DISCOUNT {discountValue ? `${discountValue}%` : ""}
                </td>
                <td style={{ border: "1px solid #ddd", padding: 5 }}>
                  {discountValue
                    ? (
                        (editData?.initial_rate +
                          editData?.extra_test_num *
                            editData?.additional_rate) *
                        (discountValue / 100)
                      ).toFixed(2)
                    : 0}
                </td>
              </tr>
            )}
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(115, 114, 114)",
                  color: "white",
                }}
              ></td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(115, 114, 114)",
                  color: "white",
                }}
                colspan="2"
              >
                INVOICE TOTAL
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(115, 114, 114)",
                  color: "white",
                }}
              >
                {!discountStatus && discountValue
                  ? (
                      editData?.initial_rate +
                      editData?.extra_test_num * editData?.additional_rate -
                      (editData?.initial_rate +
                        editData?.extra_test_num * editData?.additional_rate) *
                        (discountValue / 100)
                    ).toFixed(2)
                  : (
                      editData?.initial_rate +
                      editData?.extra_test_num * editData?.additional_rate
                    ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
          <span>
            {companyInfo?.street}, {companyInfo?.city}, {companyInfo?.state}{" "}
            {companyInfo?.zip}
          </span>
          <span style={{ marginLeft: 40 }}>{companyInfo?.phone}</span>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          width: "calc(100% - 0.6in)",
          bottom: "0.5in",
          fontFamily: "ArialFont",
        }}
      >
        <div
          style={{
            fontSize: 8,
            border: "1px solid",
            textAlign: "center",
            padding: 5,
            width: "50%",
          }}
        >
          <div style={{ marginBottom: 10 }}>Remit Payment to:</div>
          <div>{companyInfo?.company_name}</div>
          <div>{companyInfo?.street}</div>
          <div>
            {companyInfo?.city}, {companyInfo?.state} {companyInfo?.zip}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pdf;

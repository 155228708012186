/* eslint-disable */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button as AntdBtn, Input, Select, Checkbox } from "antd";
import { useSelector } from "react-redux";
// import { Select } from "antd";
// import { PDFExport, savePDF } from  '@progress/kendo-react-pdf'
import SuccessModal from "../../components/Modal/successModal";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import TrComponent from '../../components/Projects/TrMachines'
// import { getProject } from '../../data/project.js'
import {
  SendReport,
  getReportByUser,
  getReportById,
  // makePdf,
} from "../../data/report.js";
import moment from "moment";
import {
  Container,
  // Table,
  Button,
} from "reactstrap";
import * as ST from "./styles";
import getMachines from "../../data/swr/machines/machines.js";
import { getCompanyInfo } from "../../data/comany";
import Pdf from "./pdf.js";
import { getUsersByCompanyId } from "../../data/user";

const validRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const { Option } = Select;
const Projects = () => {
  // const pdfExportComponent = useRef(null)
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [editData, setEditData] = useState();
  const [reportData, setReportData] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState();
  const [layoutSelection, setLayoutSelection] = useState("size-letter");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState();
  const [otherContactStatus, setOtherContactStatus] = useState(false);
  const [isOthers, setIsOthers] = useState(false);
  const [pdfPageNumber, setPdfPageNumber] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.Login.user,
  }));
  const { result, mutate } = getMachines({ project_id: editData?.project_id });
  useEffect(() => {
    getReportByUser({ id: editData?.project_id }).then((res) => {
      setReportData(res[0]);
    });
  }, [id, editData]);
  useEffect(() => {
    if (result?.length) {
      setPdfPageNumber(Math.ceil(result?.length / 14));
    }
  }, [result]);

  useEffect(() => {
    getReportById({ id: id }).then((res) => {
      var params = {
        customer_email: res[0].customer_email,
        customer_name: res[0].customer_name,
        customer_phone: res[0].customer_phone,
        contact_name: res[0].contact_name,
        contacts: JSON.parse(res[0].contacts) || [],
        project_name: res[0].project_name,
        customer_id: res[0].customer_id,
        ses_project_id: res[0].ses_project_id,
        reference_id: res[0].reference_id,
        project_city: res[0].project_city,
        project_state: res[0].project_state,
        project_street: res[0].project_street,
        project_zip: res[0].project_zip,
        project_id: res[0].project_id,
        ID: res[0].ID,
        copy_reports_user: res[0].copy_reports_user,
        report_date: moment(res[0].report_date).format("MM/DD/YYYY HH:mm:ss"),
        city: res[0].city,
        state: res[0].state,
        street: res[0].street,
        zip: res[0].zip,
        tested_at: moment(res[0].tested_at).format("MM/DD/YYYY HH:mm:ss"),
        reporter_user_id: currentUser?.sub,
        technician_name: currentUser?.firstname + " " + currentUser?.lastname,
        job_number: res[0].job_number,
      };
      form.setFieldsValue({
        ...res[0],
        contact_email_report: res[0].contact_email,
      });
      setEditData(params);
    });
  }, [id]);
  useEffect(() => {
    getCompanyInfo({ id: currentUser?.company_id }).then((res) => {
      if (res) {
        setCompanyInfo(res[0]);
        form.setFieldsValue({ ...res[0], technician_email: currentUser.email });
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser?.company_id) {
      getUsersByCompanyId({ company_id: currentUser?.company_id }).then(
        (res) => {
          setCompanyUsers(
            res?.filter((e) => e?.firstname !== "" && e?.lastname !== "")
          );
        }
      );
    } else {
      setCompanyUsers([]);
    }
  }, []);

  const tog_center = () => {
    setmodal_center(!modal_center);
  };
  const goMachine = () => {
    history.push(`/reports`);
  };
  const sendReport = (val) => {
    setInvoiceButtonLoading(true);
    const params = {
      editData: editData,
      companyInfo: companyInfo,
      tableData: result,
      resend: true,
      currentUser: { ...currentUser, email: val.technician_email },
      ...val,
    };
    SendReport(params).then((res) => {
      if (res) {
        setmodal_center(true);
        setInvoiceButtonLoading(false);
      }
    });
  };

  const handleExportWithComponent = async (event) => {
    try {
      var pdfContent = "";
      for (var i = 0; i < pdfPageNumber; i++) {
        document.getElementsByClassName("footer-content")[i].style.display = "none";
        pdfContent += document.getElementsByClassName("pdf-page")[i].innerHTML;
        if (i !== pdfPageNumber - 1) {
          pdfContent += "<div class='page-break'></div>";
        }
        document.getElementsByClassName("footer-content")[i].style.display = "block";
      }
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/report/makePdf",
        {
          method: "POST",
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: pdfContent, data: { reportData: reportData, companyInfo: companyInfo, url: `${process.env.REACT_APP_URL}/company-logo/` } }),
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf"; // Set the filename for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // pdfExportComponent.current.save();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report | DOP Test Network</title>
        </MetaTags>
        {currentUser?.role_id !== 10 && (
          <ST.GeneratedWrapper>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title="DOP"
                breadcrumbItem="Status: Report Generated"
              />
              <div className="back d-print-none" onClick={() => goMachine()}>
                <i className="dripicons-arrow-thin-left"></i>
                <span>Back</span>
              </div>

              {/* <div style={{ textAlign: "center" }}>
                <Select
                  defaultValue={layoutSelection}
                  onChange={(e) => setLayoutSelection(e)}
                  style={{ width: 200 }}
                >
                  <Option value="size-a4">A4</Option>
                  <Option value="size-letter">Letter</Option>
                  <Option value="size-executive">Execution</Option>
                </Select>
              </div> */}
              <div className="table-responsive" style={{ paddingBottom: 15 }}>
                {Array.from({ length: pdfPageNumber }, (_, i) => (
                  <div key={i} className={`pdf-page ${layoutSelection}`}>
                    <Pdf
                      companyInfo={companyInfo}
                      editData={editData}
                      result={result}
                      reportData={reportData}
                      index={i}
                      pageLength={pdfPageNumber}
                    />
                  </div>
                ))}
              </div>
              <footer
                className="d-print-none"
                style={{ textAlign: "center", marginTop: 30 }}
              >
                <Button
                  // disabled={invoiceButtonLoading}
                  onClick={() => setIsModalVisible(true)}
                >
                  Resend Report
                </Button>
                <Button onClick={handleExportWithComponent}>
                  Download PDF
                </Button>
              </footer>
            </Container>
            <ST.StyleModal
              title="Edit"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <p>
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={sendReport}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  // initialValues={{ email: editUserData?.email }}
                >
                  <h3>Report</h3>
                  <hr />
                  <Form.Item
                    name="technician_email"
                    label="Technician:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Send copies of reports to:"
                    name="copy_reports_user"
                  >
                    <Select
                      showSearch
                      className="antdSelect"
                      defaultValue="N/A"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option.title
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {companyUsers &&
                        companyUsers.map((res) => (
                          <Option
                            key={[res.email]}
                            title={`${res.firstname} ${res.lastname}`}
                            value={res.id}
                            disabled={res.leave}
                          >
                            {res.firstname} {res.lastname}
                          </Option>
                        ))}
                      <Option value={null}>N/A</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="customer_email"
                    label="Customer:"
                    rules={[
                      {
                        validator(_, value) {
                          if (value && !value.match(validRegex)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() =>
                        setOtherContactStatus(!otherContactStatus)
                      }
                      checked={otherContactStatus}
                    >
                      Other customer contacts:
                    </Checkbox>
                  </Form.Item>
                  {otherContactStatus && (
                    <Form.Item name="contact_emails" label="Contacts:">
                      <Select
                        mode="multiple"
                        size="middle"
                        placeholder="Please select"
                        style={{
                          width: "100%",
                        }}
                      >
                        {editData?.contacts &&
                          editData.contacts.map((res, index) => (
                            <Option key={index} value={res.contact_email}>
                              {res.contact_email +
                                " (" +
                                res.contact_firstname +
                                "  " +
                                res.contact_lastname +
                                ")"}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={() => setIsOthers(!isOthers)}
                      checked={isOthers}
                    >
                      To other:
                    </Checkbox>
                  </Form.Item>
                  {isOthers && (
                    <Form.Item
                      name="other_email"
                      label="Other:"
                      rules={[
                        // { required: true, message: "This field is required" },
                        {
                          validator(_, value) {
                            if (value && !value.match(validRegex)) {
                              return Promise.reject("Invalid email address!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          height: "38px",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{ offset: 10, span: 14 }}
                    style={{ textAlign: "left" }}
                  >
                    <AntdBtn
                      htmlType="button"
                      onClick={() => setIsModalVisible(false)}
                    >
                      Cancel
                    </AntdBtn>
                    <AntdBtn
                      type="primary"
                      htmlType="submit"
                      disabled={invoiceButtonLoading}
                    >
                      Send
                    </AntdBtn>
                  </Form.Item>
                </Form>
              </p>
            </ST.StyleModal>
            <SuccessModal
              title="Successfully!"
              content="Your sent report to customer"
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
            />
          </ST.GeneratedWrapper>
        )}
      </div>
    </React.Fragment>
  );
};

export default Projects;
